import Layout from '../../hocs/Layout'
import {useParams} from 'react-router'
import { useState } from 'react'
import { connect } from 'react-redux'
import { activate } from '../../redux/actions/auth'
import { Navigate, useNavigate } from "react-router-dom";
import {Circles} from 'react-loader-spinner'
import Button from 'react-bootstrap/Button';

const Activate = ({ activate }) =>{
    const params = useParams()
    const navigate = useNavigate();
    const [isActivate, setActivate] = useState(false);
    const [loading, setLoading] = useState(false);

    const activate_account = () => {
      setLoading(true);
      try{
        const uid = params.uid
        const token = params.token
        activate(uid, token);
        setActivate(true);
      }catch (error){};
      setLoading(false);
    }
    if (isActivate && !loading){
      navigate("/")
    }

    return(
      <Layout>
        <div className="content justify-content-center align-items-center">
          <div className="row justify-content-center align-items-center">
            {loading ? 
                <Button className="col-3 button rounded-5 ">
                <Circles
                    type="Oval"
                    color="#fff"
                    width={20}
                    height={20}
                />
                </Button>:
                <Button type="submit" onClick={activate_account} className="col-3 button rounded-5">
                    Activate Account
                </Button>
            }
          </div>
        </div>
        </Layout>
    )
}

export default connect(null,{
  activate
}) (Activate)