import Layout from "../../hocs/Layout";
import { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import { signup } from "../../redux/actions/auth";
import { Circles } from "react-loader-spinner";
import { Button, Col, Form, Row, Container, Card } from "react-bootstrap";

const Signup = ({ signup }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate()
  const [validForm, setValidForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    re_password: "",
    first_name: "",
    last_name: "",
    document_id: "",
    phone: "",
    responsible: "",
    prescriptor: "",
    bank_account: "",
  });

  const {
    email,
    password,
    re_password,
    first_name,
    last_name,
    document_id,
    phone,
    responsible,
    prescriptor,
    bank_account,
  } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setValidForm(!Object.keys(formData).some((element) => !formData[element]));
  }, [formData]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await signup(
        email,
        password,
        re_password,
        first_name,
        last_name,
        document_id,
        phone,
        responsible,
        prescriptor,
        bank_account
      );
      navigate("/")
      console.log("formData", formData);
    } catch (error) {}
    setLoading(false);
  };

  return (
    <Layout>
      <Container className="text-center align-items-center justify-content-center my-3">
        <Row>
          <Col className="justify-content-center d-flex">
            <Card style={{ width: "50vw" }}>
              <Card.Header className="ab-primary">
                <Row className="align-items-center justify-content-center my-1">
                  <Col>
                    <h1 className="col-auto text-white form-label m-0">
                      Registro nuevos usuarios
                    </h1>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={(e) => onSubmit(e)}>
                  <Row className="mb-1">
                    <Col
                      md={4}
                      className="d-flex flex-column align-items-start"
                    >
                      <label>Nombre/Razón Social</label>
                      <Form.Control
                        name="first_name"
                        className="form-control"
                        size="md"
                        placeholder="Nombre/Razón Social"
                        value={first_name}
                        type="text"
                        onChange={(e) => onChange(e)}
                      ></Form.Control>
                    </Col>
                    <Col
                      md={4}
                      className="d-flex flex-column align-items-start"
                    >
                      <label>Apellidos</label>
                      <Form.Control
                        name="last_name"
                        className="form-control"
                        size="md"
                        placeholder="Apellidos"
                        value={last_name}
                        type="text"
                        onChange={(e) => onChange(e)}
                      ></Form.Control>
                    </Col>
                    <Col
                      md={4}
                      className="d-flex flex-column align-items-start"
                    >
                      <label>Documento</label>
                      <Form.Control
                        name="document_id"
                        className="form-control"
                        size="md"
                        placeholder="Documento"
                        value={document_id}
                        type="text"
                        onChange={(e) => onChange(e)}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col
                      md={6}
                      className="d-flex flex-column align-items-start"
                    >
                      <label>Teléfono</label>
                      <Form.Control
                        name="phone"
                        className="form-control"
                        size="md"
                        placeholder="Teléfono"
                        value={phone}
                        type="text"
                        onChange={(e) => onChange(e)}
                      ></Form.Control>
                    </Col>
                    <Col
                      md={6}
                      className="d-flex flex-column align-items-start"
                    >
                      <label>Correo electrónico</label>
                      <Form.Control
                        name="email"
                        className="form-control"
                        size="md"
                        placeholder="Correo electrónico"
                        value={email}
                        type="text"
                        onChange={(e) => onChange(e)}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col
                      md={6}
                      className="d-flex flex-column align-items-start"
                    >
                      <label>Gestor de cuenta</label>
                      <Form.Control
                        name="responsible"
                        className="form-control"
                        size="md"
                        placeholder="Gestor de cuenta"
                        value={responsible}
                        type="text"
                        onChange={(e) => onChange(e)}
                      ></Form.Control>
                    </Col>
                    <Col
                      md={6}
                      className="d-flex flex-column align-items-start"
                    >
                      <label>Prescriptor</label>
                      <Form.Control
                        name="prescriptor"
                        className="form-control"
                        size="md"
                        placeholder="Prescriptor"
                        value={prescriptor}
                        type="text"
                        onChange={(e) => onChange(e)}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col
                      md={6}
                      className="d-flex flex-column align-items-start"
                    >
                      <label>IBAN</label>
                      <Form.Control
                        name="bank_account"
                        className="form-control"
                        size="md"
                        placeholder="IBAN"
                        value={bank_account}
                        type="text"
                        onChange={(e) => onChange(e)}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col
                      md={6}
                      className="d-flex flex-column align-items-start"
                    >
                      <label>Contraseña</label>
                      <Form.Control
                        name="password"
                        className="form-control"
                        size="md"
                        placeholder="****"
                        value={password}
                        type="password"
                        onChange={(e) => onChange(e)}
                      ></Form.Control>
                    </Col>
                    <Col
                      md={6}
                      className="d-flex flex-column align-items-start"
                    >
                      <label>Repetir Contraseña</label>
                      <Form.Control
                        required
                        name="re_password"
                        className="form-control"
                        size="md"
                        placeholder="****"
                        value={re_password}
                        type="password"
                        onChange={(e) => onChange(e)}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <div className="justify-content-left">
                    {loading ? (
                      <Button className="col-3 button rounded-5 ">
                        <Circles
                          type="Oval"
                          color="#fff"
                          width={20}
                          height={20}
                        />
                      </Button>
                    ) : (
                      <Button
                        disabled={!validForm}
                        type="submit"
                        className="mt-3 col-3 button rounded-5"
                      >
                        Registrarse
                      </Button>
                    )}
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default connect(null, {
  signup,
})(Signup);
