import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { check_authenticated, load_user, refresh } from "../redux/actions/auth";
import { useParams } from "react-router-dom";

import { useEffect } from "react";
import { connect } from "react-redux";
import NavBar from "../components/navigation/navBar";
import Loader from "../components/Loader";
import Footer from "../components/navigation/Footer";
import { Col, Row, Container, Stack } from "react-bootstrap";
import logo_electricidad from "../media/img/Bulb_final.png";
import logo_gas from "../media/img/Gas_final.png";

const Layout = (props) => {
  useEffect(() => {
    props.refresh();
    props.check_authenticated();
    props.load_user();
  }, []);

  const params = useParams();

  return (
    <div>
      <NavBar title={props.title} />
      <Loader />
      <ToastContainer autoClose={3000} closeOnClick />
      <Container
        fluid
        className="text-center align-items-center justify-content-center main-display-initial"
      >
        <Row className="align-items-center justify-content-center mb-footer">
          <Col {...props}>
            {params.sector_type && (
              <Row className="align-items-center">
                <Col
                  md="auto"
                  className="align-items-center justify-content-center"
                >
                  <div
                    className={`text-white sector-tag p-2 ${
                      params.sector_type === "electrico"
                        ? "ab-primary-elec"
                        : "ab-primary-gas"
                    }`}
                  >
                    <Stack direction="horizontal" gap={2}>
                      <img
                        style={{
                          backgroundColor: "white",
                          borderRadius: "50%",
                        }}
                        src={
                          params.sector_type === "electrico"
                            ? logo_electricidad
                            : logo_gas
                        }
                        className="img-fluid p-2"
                        width="40"
                        title={
                          params.sector_type === "electrico"
                            ? "Electricidad"
                            : "Gas"
                        }
                        alt={
                          params.sector_type === "electrico"
                            ? "Electricidad"
                            : "Gas"
                        }
                      />
                      <h5 className="text-white m-0">
                        {params.sector_type === "electrico"
                          ? "ELECTRICIDAD"
                          : "GAS"}
                      </h5>
                    </Stack>
                  </div>
                </Col>
              </Row>
            )}
            {props.children}
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default connect(null, {
  check_authenticated,
  load_user,
  refresh,
})(Layout);
