import { Form, FloatingLabel } from "react-bootstrap";

export const TextInput = ({ name, label, hint = null, error = null, register, ...props }) => {
  return (
    <>
      <FloatingLabel controlId={`floating${name}`} label={label}>
        <Form.Control
          {...register(name)}
          name={name}
          className="form-control"
          size="md"
          placeholder={label}
          type="text"
          {...props}
        />
      </FloatingLabel>
      {error 
        ? <span className="input-feedback">{error ?? " "}</span>
        : <span className="hint">{hint ?? " "}</span>
      }
    </>
  );
};

export const SelectInput = ({
  name,
  label,
  error = null,
  hint = null,
  register,
  ...props
}) => {
  return (
    <>
      <FloatingLabel controlId={`floating${name}`} label={label}>
        <Form.Select
          {...register(name)}
          name={name}
          className="form-control form-control-md"
          size="md"
          {...props}
        />
      </FloatingLabel>
      {error 
        ? <span className="input-feedback">{error ?? " "}</span>
        : <span className="hint">{hint ?? " "}</span>
      }
    </>
  );
};
