import { useState } from "react";
import { connect } from "react-redux";
import { Modal, Row, Col, Button, ToggleButtonGroup,  Table,
  ToggleButton, Stack } from "react-bootstrap";
import { TextInput } from "../../components/inputs/Inputs";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { cupsSchema } from "../../utils/validationSchemas";
import {btn_search_sips} from "../../services/cups";

function GestionSips({ show, close }) {
  const [loading, setLoading] = useState(false);
  const [cups, setCups] = useState(false);
  const [sector_type, setSectorType] = useState(false);
  
  const {
    register,
    control,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(cupsSchema(sector_type)),
  });

  const cupsName = watch("cups_name");
  const sectorType = watch("sector_type");

  const cerrar = () => {
    close(false);
  };

  const onSearchCups = async () => {
    const data = getValues();
    setLoading(true);
    setCups(await btn_search_sips(data.cups_name,data.sector_type));
    setLoading(false);
  };

  return (
    <Modal
      show={show}
      size="lg"
      backdrop="static"
      className="border border-0"
      centered
    >
      <Modal.Header className="ab-primary justify-content-between align-items-center">
        <Col md="auto">
          <h2 className="text-white my-0">Gestión de SIPS</h2>
        </Col>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center align-items-center">
        <Col lg={7} md={12}>
          <Row
              className="align-items-center justify-content-center p-0 m-0"
              style={{ height: "25vh" }}
          >
            <Controller
                control={control}
                name="sector_type"
                render={({ field: { onChange, onBlur, value } }) => (
                  <>
                    <ToggleButtonGroup
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      type="radio"
                      name="options"
                    >
                      <ToggleButton
                        id="electrico"
                        className={
                          sectorType === "electrico"
                            ? "toggle-selected"
                            : "toggle-not-selected"
                        }
                        value={"electrico"}
                      >
                        Electricidad
                      </ToggleButton>
                      <ToggleButton
                        id="gas"
                        className={
                          sectorType === "gas"
                            ? "toggle-selected"
                            : "toggle-not-selected"
                        }
                        value={"gas"}
                      >
                        Gas
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </>
                )}
              />
            </Row>
            <Row>
              <Col lg={10} md={8} xs={8}>
                <TextInput
                  register={register}
                  name="cups_name"
                  label="CUPS"
                  error={errors.cups_name?.message}
                />
              </Col>
              <Col md={2} xs={2} className="align-self-baseline">
                <Button
                  disabled={cupsName < 20}
                  className="button rounded-5"
                  onClick={onSearchCups}
                >
                  Buscar
                </Button>
              </Col>
            </Row>
          </Col>
          {loading ? ( 
              <Row className="justify-content-center align-items-center m-5">
                <Col lg={7} md={12}>
                  <Stack direction="horizontal"  className="align-items-center justify-content-center p-0 m-0" gap={2}>
                    <span className="spinner-border spinner-border text-ab"></span>
                    <h3 className="mb-0">Buscando en Sips</h3>
                  </Stack>
                </Col>
              </Row>
            )  : cups ? (
            <>
              <Table striped hover size="lg" responsive>
                  {
                    Object.entries(cups)
                    .map( ([key, value]) =>{
                      return (
                        <>
                          <thead>
                            <tr>
                              <th className="col-lg-1 col-md-1">{key}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr  className="align-middle">
                              <td>{value}</td>
                            </tr>
                          </tbody>
                        </>
                      )
                    })
                  }
              </Table>
            </>
            ) : <></>
          }
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Col md="auto">
          <Button
            className="button rounded-5 button-secondary border-0"
            variant="secondary"
            onClick={cerrar}
          >
            Cerrar
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}

export default connect()(GestionSips);
