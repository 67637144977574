import http from "../utils/http";
import { toast } from "react-toastify";
import { store } from "../store";
import { removeLoader, setLoader } from "../redux/actions/loader";

export const get_pagador_by_vat = async (documento) => {
  try {
    store.dispatch(setLoader("Buscando cliente"));
    const res = await http.get(`/erp/partner`, { params: { documento } });
    return res.data.partner;
  } catch (err) {
    toast.error("Error al obtener el pagador.", {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};

export const get_pagador_by_id = async (id) => {
  try {
    store.dispatch(setLoader("Buscando cliente"));
    const res = await http.get(`/erp/pagador`, { params: { pagador: id } });
    return res.data.pagador;
  } catch (err) {
    toast.error("Error al obtener el pagador.", {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};

export const register_pagador = async (datos) => {
  try {
    store.dispatch(setLoader("Registrando pagador"));
    const res = await http.post(`/erp/partner`, datos, {
      params: { partnerSepa: "partnerSepa" },
    });
    return res.data.pagador;
  } catch (err) {
    toast.error("Error al registrar el pagador.", {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};

export const register_partner_bank = async (oferta_id, datos) => {
  const body = {
    oferta_id,
    datos,
  };
  try {
    store.dispatch(setLoader("Registrando banco"));
    const res = await http.post(`/erp/pagador`, body);
    return res.data.partner_bank;
  } catch (err) {
    toast.error("Error al crear el partner bank.", {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};
