import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { Modal, Row, Col } from "react-bootstrap";

function Loader({ loader }) {
  const [show, setShow] = useState(true);

  useEffect(() => {
    setShow(!!loader.counter ?? false);
  }, [loader.counter]);

  return (
    <Modal show={show} backdrop="static" className="border border-0 mt-10">
      <Modal.Header className="ab-primary justify-content-center my-0">
        <Modal.Title>
          <h2 className="text-white my-0">ab energía · Agentes</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-start align-items-center">
          <Col xs={2} className="align-self-end">
            <option className="spinner-border spinner-border text-ab"></option>
          </Col>
          <Col xs={8} className="h5 my-0">
            {loader.mensaje}...
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  loader: state.Loader,
});

export default connect(mapStateToProps)(Loader);
