import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal, Row, Col, Button } from "react-bootstrap";

function SubirArchivo({ show, close }) {
  const navigate = useNavigate();
  const cerrar = () => {
    close(false);
  };

  return (
    <Modal
      show={show}
      size="md"
      backdrop="static"
      className="border border-0"
      centered
    >
      <Modal.Header className="ab-primary justify-content-center align-items-center">
        <Col md="auto">
          <h2 className="text-white my-0">Carteras</h2>
        </Col>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center align-items-center">
          <Col lg="4" md={6} className="mb-2">
            <Button
              type="submit"
              className="button button-block"
              onClick={() => navigate("/contratos/ofertas")}
            >
              Ofertas
            </Button>
          </Col>
          <Col lg="4" md={6} className="mb-2">
            <Button
              type="submit"
              className="button button-block"
              onClick={() => navigate("/contratos/contratos")}
            >
              Contratos
            </Button>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Col md="auto">
            <Button
              className="button rounded-5 button-secondary"
              variant="secondary"
              onClick={cerrar}
            >
              Cerrar
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}

export default connect()(SubirArchivo);
