import { SET_LOADER, REMOVE_LOADER } from "../actions/types";

const initialState = {
  mensaje: null,
  counter: 0,
};

export default function Loader(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADER:
      return {
        ...state,
        mensaje: payload.mensaje,
        counter: state.counter + 1,
      };
    case REMOVE_LOADER:
      return {
        ...state,
        counter: state.counter - 1,
      };
    default:
      return state;
  }
}
