import { SET_TARIFA_OFERTA_SUCCESS } from "../actions/types";

const initialState = {
  tarifa_seleccionada: null,
};

export default function Tarifas(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_TARIFA_OFERTA_SUCCESS:
      return {
        ...state,
        tarifa_seleccionada: payload,
      };
    default:
      return state;
  }
}
