import {
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  ACTIVATION_SUCCESS,
  ACTIVATION_FAIL,
  SET_AUTH_LOADING,
  REMOVE_AUTH_LOADING,
  USER_LOADED_SUCCESS,
  USER_LOADED_FAIL,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAIL,
  REFRESH_SUCCESS,
  REFRESH_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_CONFIRM_SUCCESS,
  RESET_PASSWORD_CONFIRM_FAIL,
  LOGOUT,
} from "./types";
import axios from "axios";
import http from "../../utils/http";
import { toast } from "react-toastify";
import { store } from "../../store";
import { removeLoader, setLoader } from "../actions/loader";

export const check_authenticated = () => {
  return async (dispatch) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({
        token: localStorage.getItem("access"),
      });

      try {
        const res = await http.post(`/auth/jwt/verify/`, body, config);
        if (res.status === 200) {
          dispatch({
            type: AUTHENTICATED_SUCCESS,
          });
        } else {
          dispatch({
            type: AUTHENTICATED_FAIL,
          });
        }
      } catch (err) {
        dispatch({
          type: AUTHENTICATED_FAIL,
        });
      }
    } else {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  };
};

export const signup = (
  email,
  password,
  re_password,
  first_name,
  last_name,
  document_id,
  phone,
  responsible,
  prescriptor,
  bank_account
) => {
  return async (dispatch) => {
    dispatch({
      type: SET_AUTH_LOADING,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      email,
      password,
      re_password,
      first_name,
      last_name,
      document_id,
      phone,
      responsible,
      prescriptor,
      bank_account,
    });
    try {
      store.dispatch(setLoader("Creando Usuario, enviaremos un correo para activar tu cuenta"));
      const res = await http.post(`/auth/users/`, body, config);
      dispatch({
        type: SIGNUP_SUCCESS,
        payload: res.data,
      });
      return true;
    } catch (err) {
      toast.error("Error al crear el usuario.", {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch({
        type: SIGNUP_FAIL,
      });
      dispatch({
        type: REMOVE_AUTH_LOADING,
      });
      return false;
    } finally {
      store.dispatch(removeLoader());
    }
  };
};

export const load_user = () => {
  return async (dispatch) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };

      try {
        const res = await http.get(`/auth/users/me/`, config);
        if (res.status === 200) {
          dispatch({
            type: USER_LOADED_SUCCESS,
            payload: res.data,
          });
        } else {
          dispatch({
            type: USER_LOADED_FAIL,
          });
        }
      } catch (err) {
        dispatch({
          type: USER_LOADED_FAIL,
        });
      }
    } else {
      dispatch({
        type: USER_LOADED_FAIL,
      });
    }
  };
};

export const patch_user = (data) => {
  return async (dispatch) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };

      try {
        store.dispatch(setLoader("Actualizando datos del usuario"));
        const res = await http.patch(`/auth/users/me/`, data, config);
        if (res.status === 200) {
          dispatch({
            type: USER_LOADED_SUCCESS,
            payload: res.data,
          });
        }
      } catch (err) {
        dispatch({
          type: USER_LOADED_FAIL,
        });
        toast.error("Error al actualizar los datos.", {
          position: toast.POSITION.TOP_CENTER,
        });
      } finally {
        store.dispatch(removeLoader());
      }
    }
  };
};

export const login = (email, password) => {
  return async (dispatch) => {
    dispatch({
      type: SET_AUTH_LOADING,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      email,
      password,
    });

    try {
      const res = await http.post(`/auth/jwt/create/`, body, config);
      if (res.status === 200) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data,
        });
        dispatch(load_user());
        toast.success("Inicio de sesión con éxito.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        dispatch({
          type: LOGIN_FAIL,
        });
        dispatch({
          type: REMOVE_AUTH_LOADING,
        });
        toast.error("Error al iniciar sesion.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
      });
      dispatch({
        type: REMOVE_AUTH_LOADING,
      });
      toast.error("Error al iniciar sesion. Intenta mas tarde.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
};

export const activate = (uid, token) => {
  return async (dispatch) => {
    dispatch({
      type: SET_AUTH_LOADING,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({
      uid,
      token,
    });
    try {
      store.dispatch(setLoader("Activando Usuario"));
      const res = await http.post(`/auth/users/activation/`, body, config);
      if (res.status === 204) {
        dispatch({
          type: ACTIVATION_SUCCESS,
        });
        toast.success("Cuenta activada correctamente.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        dispatch({
          type: ACTIVATION_FAIL,
        });
        toast.error("Error al activar cuenta.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      dispatch({
        type: REMOVE_AUTH_LOADING,
      });
    } catch (err) {
      dispatch({
        type: ACTIVATION_FAIL,
      });
      dispatch({
        type: REMOVE_AUTH_LOADING,
      });
      toast.error("Error al conectar con el servidor, intenta mas tarde.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      store.dispatch(removeLoader());
    }
  };
};

export const refresh = () => {
  return async (dispatch) => {
    if (localStorage.getItem("refresh")) {
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        refresh: localStorage.getItem("refresh"),
      });

      try {
        const res = await http.post(`/auth/jwt/refresh/`, body, config);
        if (res.status === 200) {
          dispatch({
            type: REFRESH_SUCCESS,
            payload: res.data,
          });
        }
      } catch (err) {
        dispatch({
          type: REFRESH_FAIL,
        });
      }
    } else {
      dispatch({
        type: REFRESH_FAIL,
      });
    }
  };
};

export const reset_password = (email) => {
  return async (dispatch) => {
    dispatch({
      type: SET_AUTH_LOADING,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ email });

    try {
      const res = await http.post(`/auth/users/reset_password/`, body, config);
      if (res.status === 204) {
        dispatch({
          type: RESET_PASSWORD_SUCCESS,
        });
        dispatch({
          type: REMOVE_AUTH_LOADING,
        });
        toast.success("Se ha enviado un email para cambiar la contraseña.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        dispatch({
          type: RESET_PASSWORD_FAIL,
        });
        dispatch({
          type: REMOVE_AUTH_LOADING,
        });
        toast.error("Error al enviar el mail de cambio de contraseña, intenta mas tarde.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (err) {
      dispatch({
        type: RESET_PASSWORD_FAIL,
      });
      dispatch({
        type: REMOVE_AUTH_LOADING,
      });
      toast.error("Error al enviar el mail de cambio de contraseña, intenta mas tarde.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
};

export const reset_password_confirm = (
  uid,
  token,
  new_password,
  re_new_password
) => {
  return async (dispatch) => {
    dispatch({
      type: SET_AUTH_LOADING,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      uid,
      token,
      new_password,
      re_new_password,
    });

    if (new_password !== re_new_password) {
      dispatch({
        type: RESET_PASSWORD_CONFIRM_FAIL,
      });
      dispatch({
        type: REMOVE_AUTH_LOADING,
      });
      toast.error("Las contraseñas no coinciden.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      try {
        const res = await http.post(`/auth/users/reset_password_confirm/`, body, config);
        if (res.status === 204) {
          dispatch({
            type: RESET_PASSWORD_CONFIRM_SUCCESS,
          });
          dispatch({
            type: REMOVE_AUTH_LOADING,
          });
          toast.success("La contraseña a sido cambiada correctamente.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          dispatch({
            type: RESET_PASSWORD_CONFIRM_FAIL,
          });
          dispatch({
            type: REMOVE_AUTH_LOADING,
          });
          toast.error("Error al cambiar la contraseña.", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (err) {
        dispatch({
          type: RESET_PASSWORD_CONFIRM_FAIL,
        });
        dispatch({
          type: REMOVE_AUTH_LOADING,
        });
        toast.error("Error al cambiar la contraseña.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };
};

export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
  toast.success(
    "Te enviamos un correo, por favor activa tu cuenta. Revisa el correo de spam.",
    {
      position: toast.POSITION.BOTTOM_RIGHT,
    }
  );
};
