import Layout from "../../hocs/Layout";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Card, Form, Container } from "react-bootstrap/";
import { TextInput } from "../../components/inputs/Inputs";
import { reset_password } from "../../redux/actions/auth";
import { Circles } from "react-loader-spinner";
import Logo from "../../media/img/abenergia-logo.png";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const ResetPassword = ({ reset_password }) => {
  const {
    register,
    watch,
    handleSubmit,
    getValues,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
    defaultValues: { email: "" },
    resolver: yupResolver(
      Yup.object({
        email: Yup.string()
          .email("Ingrese un correo válido")
          .required("Requerido"),
      })
    ),
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    try {
      reset_password(email);
      navigate("/");
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const email = watch("email");

  return (
    <Layout>
      <Container className="align-items-center justify-content-center my-3">
        <Row className="align-items-center justify-content-center mt-5">
          <Col md={4} sm={8} xs={12} className="justify-content-center">
            <Card>
              <Card.Header className="ab-secondary">
                <Row className="align-items-center justify-content-center my-1">
                  <Col className="text-center">
                    <h1 className="text-white form-label m-0">
                      Recupera tu contraseña
                    </h1>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <label className="form-label mt-3">
                    Enviar correo electrónico a:
                  </label>
                  <div className="">
                    <TextInput
                      register={register}
                      name="email"
                      label="Correo electrónico"
                      error={errors.email?.message}
                    />
                  </div>

                  <Row className="justify-content-center align-items-center">
                    <Col lg={8} md={12}>
                      <Row className="justify-content-evenly mx-5">
                        <Col className="mb-2">
                          {loading ? (
                            <Button className="button rounded-5 button-block">
                              <Circles
                                type="Oval"
                                color="#fff"
                                width={20}
                                height={20}
                              />
                            </Button>
                          ) : (
                            <Button
                              type="submit"
                              className="button rounded-5 button-block"
                              disabled={!isValid}
                            >
                              Enviar Email
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default connect(null, {
  reset_password,
})(ResetPassword);
