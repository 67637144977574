import { useState, useEffect } from "react";
import Layout from "../../hocs/Layout";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { Row, Col, Button, Card, Form, Container } from "react-bootstrap/";
import logo_login from "../../media/img/Iniciar sesion_final.png";
import logo_pregunta from "../../media/img/olvidado_icon.png";
import { connect } from "react-redux";
import { login } from "../../redux/actions/auth";
import { Circles } from "react-loader-spinner";
import { TextInput } from "../../components/inputs/Inputs";
import { useForm } from "react-hook-form";
import { loginSchema } from "../../utils/validationSchemas";
import { yupResolver } from "@hookform/resolvers/yup";

const Login = ({ login, isAuthenticated }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const defaultValues = {
    email: "",
    password: "",
  };

  const {
    register,
    handleSubmit,
    getValues,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(loginSchema),
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  const onSubmit = async () => {
    const data = getValues();
    setLoading(true);
    await login(data.email, data.password);
    setLoading(false);
  };

  return (
    <Layout>
      <Container className="align-items-center justify-content-center my-3">
        <Row className="align-items-center justify-content-center">
          <Col md={4} sm={8} xs={12} className="justify-content-center">
            <Card>
              <Card.Header className="ab-secondary">
                <Row className="align-items-center justify-content-center my-1">
                  <Col md={3} sm={3} xs={3} className="d-flex justify-content-center">
                    <img
                      width="60%"
                      src={logo_login}
                      className="img-fluid form-label"
                      alt="login"
                    />
                  </Col>
                  <Col>
                    <h1 className="text-white form-label m-0">
                      Iniciar sesión
                    </h1>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Form className="m-4" onSubmit={handleSubmit(onSubmit)}>
                  <Row className="justify-content-start align-items-center mb-2">
                    <Col md={12}>
                      <TextInput
                        register={register}
                        name="email"
                        label="Usuario"
                        error={errors.email?.message}
                      />
                      <TextInput
                        register={register}
                        name="password"
                        label="Contraseña"
                        error={errors.password?.message}
                        type="password"
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-end align-items-center">
                    <Col md={"auto"}>
                      <Link to="/reset_password" className="form-label">
                        ¿Has olvidado la contraseña?
                      </Link>
                    </Col>
                    <Col md={2} className="pl-0">
                      <img
                        width={30}
                        src={logo_pregunta}
                        className="img-fluid float-end"
                        alt="olvidado"
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-center my-3">
                    <Col lg="auto" xs="auto">
                      <Row className="justify-content-evenly">
                        {loading ? (
                          <Button className="button rounded-5 button-block">
                            <Circles
                              type="Oval"
                              color="#fff"
                              width={20}
                              height={20}
                            />
                          </Button>
                        ) : (
                          <Button
                            type="submit"
                            disabled={!isValid}
                            className="button rounded-5 button-block"
                          >
                            Acceder
                          </Button>
                        )}
                      </Row>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col md="auto" className="hint">
                      Si desea ser agente colaborador de ab energía,
                      <br />
                      contacte con nosotros en el 900 1903 00
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.Auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  login,
})(Login);
