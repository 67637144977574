import Layout from '../../hocs/Layout'
import { useState, useEffect } from 'react'
import {connect} from 'react-redux'
import { reset_password_confirm } from '../../redux/actions/auth'
import {Circles} from 'react-loader-spinner'
import {Navigate, useParams} from 'react-router'
import Logo from '../../media/img/abenergia-logo.png';
import Button from 'react-bootstrap/esm/Button'

const ResetPasswordConfirm = ({
  reset_password_confirm,
}) => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [requestSent, setRequestSent] = useState(false);

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  const [formData, setFormData] = useState({
    new_password: '',
    re_new_password: ''
  })

  const { 
    new_password,
    re_new_password
  } = formData;

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = e =>{
    e.preventDefault();
    setLoading(true);
    try{
      const uid = params.uid
      const token = params.token
      reset_password_confirm(uid, token, new_password, re_new_password)
      if (new_password === re_new_password)
        setRequestSent(true);
    }
    catch(error){
    }
    setLoading(false);
  }

  if (requestSent && !loading)
        return <Navigate to='/' />;

  return (
    <Layout>
       <div className="container text-center">
        <div className="m-o vh-100 row align-items-center justify-content-center mt-5">
            <div className='col-auto text-center mt-5'>
                <h2 className="">Recupera tu clave</h2>
                <img src={Logo} className='img-fluid mt-3' alt="logo"/>
            <form onSubmit={e=>onSubmit(e)} className="">
              <div className='row justify-content-center align-items-center mt-5'>
                <label htmlFor="email" className="col form-label">
                  Contraseña:
                </label>
                <div className='col'>
                    <input
                    name="new_password"
                    value={new_password}
                    onChange={e=>onChange(e)}
                    type="password"
                    placeholder="Contraseña"
                    required
                    className="form-control recuadro_ab rounded-5"
                    />
                </div>
              </div>
              <div className='row justify-content-center align-items-center mt-5'>
                <label htmlFor="email" className="col form-label">
                  Repetir Contraseña
                </label>
                <div className="col">
                  <input
                    name="re_new_password"
                    value={re_new_password}
                    onChange={e=>onChange(e)}
                    type="password"
                    placeholder="Repetir Contraseña"
                    required
                    className="form-control recuadro_ab rounded-5"
                  />
                </div>
              </div>

              <div>
                {loading ? 
                <Button
                className="button rounded-5 mt-5"
              >
                <Circles
                type="Oval"
                color="#fff"
                width={20}
                height={20}
                />
              </Button>:
              <Button
              type="submit"
              className="button rounded-5 mt-5"
            >
              Reset password
            </Button>}
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default connect(null, {
  reset_password_confirm
}) (ResetPasswordConfirm)