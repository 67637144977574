import Layout from "../../hocs/Layout";
import { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  get_paises,
  get_provincias,
  get_municipios,
  get_poblaciones,
  get_tipovia,
  get_distribuidoras_gas,
  get_cnaes,
} from "../../redux/actions/auxiliares";
import { setLoader, removeLoader } from "../../redux/actions/loader";
import {
  find_or_create_cups,
  get_cups,
  set_cups_erp,
  generar_precios,
  btn_import_sips,
} from "../../services/cups";
import { get_partner_by_vat } from "../../services/partner";
import { get_datos_oferta_erp, set_oferta_erp } from "../../services/ofertas";
import { getDistribuidoras } from "../../services/auxiliares";
import {
  Button,
  Col,
  Form,
  Row,
  Container,
  Card,
  FloatingLabel,
} from "react-bootstrap";
import { TextInput, SelectInput } from "../../components/inputs/Inputs";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { cupsSchema } from "../../utils/validationSchemas";

const autoconsumos = [
  { id: "00", name: "Sin Autoconsumo" },
  { id: "01", name: "Autoconsumo Tipo 1" },
  {
    id: "2A",
    name: "Autoconsumo tipo 2 (según el Art. 13. 2. a) RD 900/2015)",
  },
  {
    id: "2B",
    name: "Autoconsumo tipo 2 (según el Art. 13. 2. b) RD 900/2015)",
  },
  {
    id: "2G",
    name: "Servicios auxiliares de generación ligada a un autoconsumo tipo 2",
  },
  { id: "31", name: "Sin Excedentes Individual – Consumo" },
  { id: "32", name: "Sin Excedentes Colectivo – Consumo" },
  {
    id: "33",
    name: "Sin Excedentes Colectivo con acuerdo de compensación – Consumo",
  },
  { id: "41", name: "Con excedentes y compensación Individual – Consumo" },
  { id: "42", name: "Con excedentes y compensación Colectivo – Consumo" },
  {
    id: "43",
    name: "Con excedentes y compensación Colectivo a través de red – Consumo",
  },
  {
    id: "51",
    name: "Con excedentes sin compensación Individual sin cto de SSAA en Red Interior – Consumo",
  },
  {
    id: "52",
    name: "Con excedentes sin compensación Colectivo sin cto de SSAA en Red Interior – Consumo",
  },
  {
    id: "53",
    name: "Con excedentes sin compensación Individual con cto SSAA en Red Interior – Consumo",
  },
  {
    id: "54",
    name: "Con excedentes sin compensación individual con cto SSAA en Red Interior – SSAA",
  },
  {
    id: "55",
    name: "Con excedentes sin compensación Colectivo/en Red Interior – Consumo",
  },
  {
    id: "56",
    name: "Con excedentes sin compensación Colectivo/en Red Interior – SSAA",
  },
  {
    id: "57",
    name: "Con excedentes sin compensación Colectivo sin cto de SSAA (despreciable) en red interior – Consumo",
  },
  {
    id: "58",
    name: "Con excedentes sin compensación Colectivo sin cto de SSAA a través de red – Consumo",
  },
  {
    id: "61",
    name: "Con excedentes sin compensación Individual con cto SSAA a través de red – Consumo",
  },
  {
    id: "62",
    name: "Con excedentes sin compensación individual con cto SSAA a través de red – SSAA",
  },
  {
    id: "63",
    name: "Con excedentes sin compensación Colectivo a través de red – Consumo",
  },
  {
    id: "64",
    name: "Con excedentes sin compensación Colectivo a través de red – SSAA",
  },
  {
    id: "71",
    name: "Con excedentes sin compensación Individual con cto SSAA a través de red y red interior – Consumo",
  },
  {
    id: "72",
    name: "Con excedentes sin compensación individual con cto SSAA a través de red y red interior – SSAA",
  },
  {
    id: "73",
    name: "Con excedentes sin compensación Colectivo con cto de SSAA  a través de red y red interior – Consumo",
  },
  {
    id: "74",
    name: "Con excedentes sin compensación Colectivo con cto de SSAA a través de red y red interior – SSAA",
  },
];

const OfertaCups = ({
  setLoader,
  removeLoader,
  paises,
  provincias,
  municipios,
  poblaciones,
  tipovia,
  distribuidorasGas,
  cnaes,
  get_paises,
  get_provincias,
  get_municipios,
  get_poblaciones,
  get_tipovia,
  get_distribuidoras_gas,
  get_cnaes,
}) => {
  const location = useLocation();

  useEffect(() => {
    const get_datos_iniciales = async () => {
      setLoader("Cargando datos iniciales");
      await get_paises();
      await get_tipovia();
      await get_cnaes();
      if (params.sector_type === "gas") {
        await get_distribuidoras_gas();
      }
      removeLoader();
      setPartnerLoading(true);
      const oferta = await get_datos_oferta_erp(params.oferta_id);
      setOfertaErp(oferta);
    };
    if (location.state) {
      setEditMode(location.state.editMode);
    }
    get_datos_iniciales();
    // window.scrollTo(0, 0);
  }, []);

  const defaultValues = {
    mismaDireccion: true,
    cups_name: "",
    consumo_anual_previsto: "",
    autoconsumo_id: "",
    cups_distribuidora_id: "",
    cups_distribuidora_name: "",
    cnae: "",
    cnae_desc: "",
    cups_ref_catastral: "",
    cups_aclarador: "",
    // pressio_cups: "",
    // caudal_diario: "",
    cups_nv: "",
    cups_pt: "",
    cups_pu: "",
    cups_es: "",
    cups_pnp: "",
    cups_dp: "",
    cups_id_pais: "",
    cups_id_provincia: "",
    cups_id_municipi: "",
    cups_id_poblacio: "",
    cups_tv: "",
  };

  const regexpPotencia = new RegExp("^[0-9]+,*([0-9])*$");
  const params = useParams();
  const sector_type = params.sector_type;
  const {
    register,
    handleSubmit,
    control,
    watch,
    getValues,
    setValue,
    reset,
    trigger,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(cupsSchema(sector_type)),
  });

  const navigate = useNavigate();
  const [editMode, setEditMode] = useState(false);
  const [oferta_erp, setOfertaErp] = useState(null);
  const [partner, setPartner] = useState(null);
  const [partnerLoading, setPartnerLoading] = useState(false);
  const [loadingProv, setLoadingProv] = useState(false);
  const [loadingMuni, setLoadingMuni] = useState(false);
  const [loadingPob, setLoadingPob] = useState(false);
  const [cups, setCups] = useState(false);
  const [formPotencias, setFormPotencias] = useState({});
  const [potenciasCheck, setPotenciasCheck] = useState(true);

  const handleChangeDireccion = (value) => {
    setValue("mismaDireccion", value);
    if (!value) {
      const data = getValues();
      reset({
        mismaDireccion: value,
        cups_name: data.cups_name,
        consumo_anual_previsto: data.consumo_anual_previsto,
        autoconsumo_id: data.autoconsumo_id,
        cups_distribuidora_id: data.cups_distribuidora_id,
        cups_distribuidora_name: data.cups_distribuidora_name,
        cnae: data.cnae,
        cups_ref_catastral: data.cups_ref_catastral,
        // pressio_cups: data.pressio_cups,
        // caudal_diario: data.caudal_diario,
      });
    } else {
      setPartnerData(partner);
    }
  };

  const setPartnerData = (partner) => {
    const data = getValues();
    reset({
      ...data,
      cups_aclarador: partner.aclarador,
      cups_nv: partner.nv,
      cups_pt: partner.pt,
      cups_pu: partner.pu,
      cups_es: partner.es,
      cups_pnp: partner.pnp,
      cups_dp: partner.zip,
      cups_tv: partner.tv[0],
      cups_id_pais: partner.country[0],
      cups_id_provincia: partner.state_id[0],
      cups_id_municipi: partner.id_municipi[0],
      cups_id_poblacio: partner.id_poblacio ? partner.id_poblacio[0] : "",
    });
    const setLocation = async () => {
      setLoader("Cargando cliente");
      await get_provincias(partner.country[0]);
      await get_municipios(partner.state_id[0]);
      await get_poblaciones(partner.id_municipi[0]);
      setPartnerLoading(false);
      removeLoader();
    };
    setLocation();
  };

  const fetchProvincias = async (paisId) => {
    if (parseInt(paisId) && !partnerLoading) {
      setLoadingProv(true);
      setValue("cups_id_provincia", "");
      setValue("cups_id_municipi", "");
      setValue("cups_id_poblacio", "");
      await get_provincias(paisId);
      setLoadingProv(false);
    }
  };

  const fetchMunicipios = async (provId) => {
    if (parseInt(provId) && !partnerLoading) {
      setLoadingMuni(true);
      setValue("cups_id_municipi", "");
      setValue("cups_id_poblacio", "");
      await get_municipios(provId);
      setLoadingMuni(false);
    }
  };

  const fetchPoblaciones = async (muniId) => {
    if (parseInt(muniId) && !partnerLoading) {
      setLoadingPob(true);
      await get_poblaciones(muniId);
      setValue("cups_id_poblacio", "");
      setLoadingPob(false);
    }
  };

  const fetchDistribuidora = async (cupsName) => {
    setValue("cups_distribuidora_id", "");
    setValue("cups_distribuidora_name", "");
    if (cupsName.length >= 6) {
      const distribuidora = await getDistribuidoras(cupsName);
      if (distribuidora) {
        setValue("cups_distribuidora_id", distribuidora.id, {
          shouldValidate: true,
        });
        setValue("cups_distribuidora_name", distribuidora.name, {
          shouldValidate: true,
        });
      }
    }
  };

  useEffect(() => {
    if (oferta_erp) {
      const elec = oferta_erp.sector_type === "electrico";
      const fetchPartner = async () => {
        const partner = await get_partner_by_vat(oferta_erp.cliente_vat);
        setPartner(partner);
        setPartnerData(partner);
      };
      if (oferta_erp.cliente_vat) {
        fetchPartner();
      } else {
        setValue("mismaDireccion", false);
      }
      //seteo de campos de potencias
      let potencias = {};
      if (elec) {
        const cantPotencias = oferta_erp.tarifa_oferta[0] === 41 ? 2 : 6;
        for (let index = 0; index < cantPotencias; index++) {
          potencias[`P${index + 1}`] = "";
        }
        if (oferta_erp.potencias) {
          Object.keys(oferta_erp.potencias).forEach((pot) => {
            const potencia = oferta_erp.potencias[pot];
            potencias[pot] = potencia.toString().replace(".", ",");
          });
        }
      }
      setFormPotencias(potencias);
      setValue("autoconsumo_id", oferta_erp.autoconsumo);
      if (oferta_erp.cups_ref_catastral) {
        setValue("cups_ref_catastral", oferta_erp.cups_ref_catastral);
      }

      if (elec ? oferta_erp.cups_name : oferta_erp.cups_global) {
        setValue(
          "cups_name",
          elec ? oferta_erp.cups_name : oferta_erp.cups_global
        );
        //onSearchCups();
      }
      if (elec ? oferta_erp.cnae : oferta_erp.cnae_gas) {
        const cnae = elec ? oferta_erp.cnae : oferta_erp.cnae_gas;
        setValue("cnae", cnae);
        changeCnae(cnae);
        console.log('cnae',cnae)
      }
      if (
        elec
          ? oferta_erp.consumo_anual_previsto_directo
          : oferta_erp.consumo_anual_gas_previsto_directo
      ) {
        setValue(
          "consumo_anual_previsto",
          elec
            ? oferta_erp.consumo_anual_previsto_directo
            : oferta_erp.consumo_anual_gas_previsto_directo
        );
      }
      //Seteo la distribuidora en electricidad
      if (elec && oferta_erp.distribuidora_id) {
        setValue("cups_distribuidora_id", oferta_erp.distribuidora_id[0], {
          shouldValidate: true,
        });
        setValue("cups_distribuidora_name", oferta_erp.distribuidora_id[1], {
          shouldValidate: true,
        });
      } else {
        fetchDistribuidora(oferta_erp.cups_name);
      }
    }
  }, [oferta_erp]);

  const onChangePotencia = (e) => {
    let formPots = "";
    if (e.target.value) {
      if (regexpPotencia.test(e.target.value)) {
        formPots = { ...formPotencias, [e.target.name]: e.target.value };
        setFormPotencias({ ...formPotencias, [e.target.name]: e.target.value });
      }
    } else {
      formPots = { ...formPotencias, [e.target.name]: e.target.value };
      setFormPotencias({ ...formPotencias, [e.target.name]: e.target.value });
    }
    const check = Object.keys(formPots).every((pot) => {
      return !!formPots[pot];
    });
    setPotenciasCheck(check);
  };

  const onSearchCups = async () => {
    const data = getValues();
    if (sector_type=='gas'){
      const cups = await get_cups(
        data.cups_name,
        oferta_erp.user_id[0],
        oferta_erp.id,
        sector_type
      );
    }
    else{
      const cups = await btn_import_sips(
        data.cups_name,
        oferta_erp.id,
      );
      if (cups){
        const oferta = await get_datos_oferta_erp(oferta_erp.id);
        setOfertaErp(oferta);
        const cnae = oferta.cnae ;
        setValue("cnae", cnae);
        changeCnae(cnae); 
      }
    }
    
    if (cups && sector_type=='electrico') {
      setCups(cups);
      const oferta = await get_datos_oferta_erp(params.oferta_id);
      setOfertaErp(oferta);
      if (!mismaDireccion) {
        // Si !mismaDireccion, seteo los datos del cups pisando los del partner
        // si mismaDireccion, los datos del partner sobreescriben los datos del cups al guardar
        reset({
          ...data,
          aclarador: cups.aclarador,
          es: cups.es,
          pt: cups.pt,
          pu: cups.pu,
          tv: cups.tv[0],
          nv: cups.nv,
          pnp: cups.pnp,
          dp: cups.dp,
          name: cups.name,
          ref_catastral: cups.ref_catastral,
          id_municipi: cups.id_municipi[0],
          id_poblacio: cups.id_poblacio[0],
          id_provincia: cups.id_provincia[0],
          cpo: cups.cpo,
          cpa: cups.cpa,
        });
      }
    } else {
      setCups(false);
      fetchDistribuidora(data.cups_name);
    }
    // window.scrollTo(0, 0);
  };

  const onSubmit = async () => {
    const elec = oferta_erp.sector_type === "electrico";
    const data = getValues();
    const potencias = {};
    Object.keys(formPotencias).forEach(
      (pot) => (potencias[pot] = formPotencias[pot])
    );
    const tipus = "oferta";
    const datosCups = {
      cups_name: data.cups_name,
      cups_distribuidora_id: data.cups_distribuidora_id,
      cups_ref_catastral: data.cups_ref_catastral,
      cups_id_provincia: data.cups_id_provincia,
      cups_id_municipi: data.cups_id_municipi,
      cups_id_poblacio: data.cups_id_poblacio,
      cups_aclarador: data.cups_aclarador,
      cups_tv: data.cups_tv,
      cups_nv: data.cups_nv,
      cups_pt: data.cups_pt,
      cups_pu: data.cups_pu,
      cups_es: data.cups_es,
      cups_pnp: data.cups_pnp,
      cups_dp: data.cups_dp,
      [elec ? "cnae" : "cnae_gas"]: data.cnae,
    };
    const dataOferta = elec
      ? {
          ...datosCups,
          autoconsumo: data.autoconsumo_id,
          //consumo_anual_previsto: data.consumo_anual_previsto,
          consumo_anual_previsto_directo: data.consumo_anual_previsto,
          potencia_ofertada: potencias.P1,
          potencia_client: potencias.P1,
        }
      : {
          ...datosCups,
          // caudal_diario: data.caudal_diario,
          consumo_anual_gas_previsto_directo: data.consumo_anual_previsto,
          // pressio_cups: data.pressio_cups,
        };
    const dataCups = {
      ...datosCups,
      sector_type: sector_type,
      cups_global: data.cups_name,
      cups_cpo: null,
      cups_cpa: null,
    };
    let cup = {};
    try {
      if (cups) {
        cup = await set_cups_erp(cups.id, dataCups);
      } else {
        let params = { dataCups };
        if (elec) {
          params.oferta_id = oferta_erp.id;
          params.tarifa_oferta = oferta_erp.tarifa_oferta[0];
        }
        cup = await find_or_create_cups(params);
      }
      dataOferta[elec ? "cups_id" : "cups_gas_id"] = cup.cups_id ?? cup.id;
      dataOferta.cups_global = data.cups_name;
      const oferta = await set_oferta_erp(
        oferta_erp.id,
        dataOferta,
        oferta_erp.sector_type
      );
      if (elec) {
        await generar_precios(
          oferta.id,
          tipus,
          oferta.llista_preu[0],
          potencias
        );
      }
      navigate("/contratos/ofertas");
    } catch (error) {}
    // window.scrollTo(0, 0);
  };

  const changeCnae = (value) => {
    let cnaeDesc = cnaes.find((c) => c.name === value);
    if (cnaeDesc) {
      cnaeDesc = cnaeDesc.descripcio;
    } else {
      cnaeDesc = "No existe el CNAE";
    }
    setValue("cnae_desc", cnaeDesc);
  };

  const idPais = watch("cups_id_pais");
  const idProv = watch("cups_id_provincia");
  const idMuni = watch("cups_id_municipi");
  const mismaDireccion = watch("mismaDireccion");
  const cupsName = watch("cups_name");
  const cnaeDesc = watch("cnae_desc");

  return (
    <Layout lg="6" md="8" title="Datos punto de suministro">
      <Card className="card-label-corner">
        {/* <Card.Header
          className={
            sector_type === "electrico" ? "ab-primary-elec" : "ab-primary-gas"
          }
        >
          <Row className="align-items-center justify-content-center my-1">
            <Col className="text-center" md={{ span: 9, offset: 1 }}>
              <h1 className="col-auto text-white form-label m-0">
                Datos punto de suministro
              </h1>
            </Col>
            <Col md="2">
              <img
                style={{ backgroundColor: "white", borderRadius: "50%" }}
                src={sector_type === "electrico" ? logo_electricidad : logo_gas}
                className="img-fluid p-2"
                width="40"
                title={sector_type === "electrico" ? "Electricidad" : "Gas"}
                alt={sector_type === "electrico" ? "Electricidad" : "Gas"}
              />
            </Col>
          </Row>
        </Card.Header> */}
        <Card.Body>
          <Row className="justify-content-center align-items-center mt-2">
            <Col>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <DevTool control={control} />
                <Row className="mb-1 justify-content-between align-items-center">
                  <Col lg={7} md={12}>
                    <Row>
                      <Col lg={10} md={8} xs={8}>
                        <TextInput
                          register={register}
                          name="cups_name"
                          label="CUPS"
                          error={errors.cups_name?.message}
                        />
                      </Col>
                      <Col md={2} xs={2} className="align-self-baseline mt-2">
                        <Button
                          disabled={cupsName < 20}
                          className="button rounded-5"
                          onClick={onSearchCups}
                        >
                          Buscar
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={4} md={6}>
                  {sector_type === "electrico" ? (
                    <TextInput
                      register={register}
                      name="consumo_anual_previsto"
                      label="Consumo Anual (KWh)"
                      /*error={errors.consumo_anual_previsto?.message}*/
                      readOnly
                    />):(
                      <TextInput
                      register={register}
                      name="consumo_anual_previsto"
                      label="Consumo Anual (KWh)"
                      /*error={errors.consumo_anual_previsto?.message}*/
                    />)
                  }
                  </Col>
                </Row>
                <Row className="mb-2">
                  {sector_type === "electrico" && (
                    <Col md={6}>
                      <SelectInput
                        register={register}
                        name="autoconsumo_id"
                        label="Autoconsumo (KWh)"
                        error={errors.autoconsumo_id?.message}
                      >
                        <option key="null" value="" disabled selected>
                          Autoconsumo
                        </option>
                        {autoconsumos &&
                          autoconsumos.map((autoconsumo) => {
                            return (
                              <option
                                key={autoconsumo.id ?? "x"}
                                name="autoconsumo_id"
                                id="autoconsumo_id"
                                value={autoconsumo.id}
                              >
                                {autoconsumo.name}
                              </option>
                            );
                          })}
                      </SelectInput>
                    </Col>
                  )}
                  <Col md={6}>
                    {sector_type === "electrico" ? (
                      <TextInput
                        register={register}
                        name="cups_distribuidora_name"
                        label="Distribuidora"
                        error={errors.cups_distribuidora_name?.message}
                        readOnly
                      />
                    ) : cups ? (
                      <TextInput
                        register={register}
                        name="cups_distribuidora_name"
                        label="Distribuidora"
                        error={errors.cups_distribuidora_name?.message}
                       readOnly 
                      />
                    ) : (
                      <SelectInput
                        register={register}
                        name="cups_distribuidora_id"
                        label="Distribuidora"
                        error={errors.cups_distribuidora_id?.message}
                      >
                        {distribuidorasGas &&
                          distribuidorasGas.map((dist) => {
                            return (
                              <option
                                disabled={!dist.id}
                                selected={!dist.id}
                                key={dist.id}
                                value={dist.id}
                              >
                                {dist.name}
                              </option>
                            );
                          })}
                      </SelectInput>
                    )}
                  </Col>
                </Row>
                {/* {sector_type === "gas" && (
                    <Row className="mb-2">
                      <Col md={6}>
                        <TextInput
                          register={register}
                          name="caudal_diario"
                          label="Caudal diario (KWh/dia)"
                          error={errors.caudal_diario?.message}
                        />
                      </Col>
                      <Col md={6}>
                        <TextInput
                          register={register}
                          name="pressio_cups"
                          label="Presión contratada (bares)"
                          error={errors.pressio_cups?.message}
                        />
                      </Col>
                    </Row>
                  )} */}
                {sector_type === "electrico" && (
                  <Container className="form-control border-ab mb-1">
                    <Row className="mb-3">
                      <Col>
                        <label>Potencia ofertada por periodo</label>
                      </Col>
                    </Row>
                    <Row className="mb-1">
                      {!!Object.keys(formPotencias) &&
                        Object.keys(formPotencias).map((pot) => {
                          return (
                            <Col md={6} key={pot}>
                              <label>{pot}(KW)</label>
                              <Form.Control
                                name={pot}
                                value={formPotencias[pot]}
                                size="sm"
                                type="text"
                                placeholder="Potencia ofertada por periodo"
                                onChange={onChangePotencia}
                              />
                            </Col>
                          );
                        })}
                    </Row>
                  </Container>
                )}
                <Row className="justify-content-start align-items-center mb-2">
                  <Col lg={2} md={4} xs={4}>
                    <TextInput
                      register={register}
                      name="cnae"
                      label="CNAE"
                      error={errors.cnae?.message}
                      onChange={(e) => {
                        setValue("cnae", e.target.value);
                        changeCnae(e.target.value);
                      }}
                    />
                  </Col>
                  <Col lg={10} md={8} xs={8}>
                    <FloatingLabel
                      controlId={`floatingCNAE`}
                      label={cnaeDesc ? "Descripción" : ""}
                    >
                      <Form.Control
                        value={cnaeDesc}
                        className="form-control"
                        size="md"
                        placeholder={"Descripción del CNAE"}
                        type="text"
                        readOnly
                      />
                    </FloatingLabel>
                    <span className="input-feedback"> </span>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col lg={6} md={12}>
                    <TextInput
                      register={register}
                      name="cups_ref_catastral"
                      label="Ref. Catastral"
                      error={errors.cups_ref_catastral?.message}
                    />
                  </Col>
                </Row>
                <Row className="justify-content-start align-items-center mb-2">
                  <Col lg="auto">
                    <Controller
                      control={control}
                      name="mismaDireccion"
                      render={({ field: { onBlur, value } }) => (
                        <>
                          <Form.Group controlId="formBasicCheckbox">
                            <Form.Check
                              onBlur={onBlur}
                              defaultChecked
                              name="mismaDireccion"
                              type="switch"
                              onChange={(e) =>
                                handleChangeDireccion(e.target.checked)
                              }
                              value={value}
                              label="Misma direccion cliente"
                            />
                          </Form.Group>
                        </>
                      )}
                    />
                  </Col>
                </Row>
                {mismaDireccion === false && (
                  <>
                    <Row className="justify-content-start align-items-center mb-2">
                      <Col md={6}>
                        <SelectInput
                          register={register}
                          name="cups_id_pais"
                          label="País"
                          error={errors.cups_id_pais?.message}
                          onChange={(e) => {
                            setValue("cups_id_pais", e.target.value);
                            fetchProvincias(e.target.value);
                          }}
                        >
                          {paises &&
                            paises.map((pais) => {
                              return (
                                <option
                                  selected={!pais.id}
                                  disabled={!pais.id}
                                  key={pais.id}
                                  name="pais"
                                  id="pais"
                                  value={pais.id}
                                >
                                  {pais.name}
                                </option>
                              );
                            })}
                        </SelectInput>
                      </Col>
                      <Col md={loadingProv ? 5 : 6}>
                        <SelectInput
                          register={register}
                          name="cups_id_provincia"
                          label="Provincia"
                          error={errors.cups_id_provincia?.message}
                          onChange={(e) => {
                            setValue("cups_id_provincia", e.target.value);
                            fetchMunicipios(e.target.value);
                          }}
                          disabled={!idPais || loadingProv || !provincias || !provincias.length}
                        >
                          {loadingProv ? (
                            <option>Cargando provincias...</option>
                          ) : (
                            provincias &&
                            provincias.map((prov) => {
                              return (
                                <option
                                  selected={!prov.id}
                                  disabled={!prov.id}
                                  key={prov.id}
                                  name="provincia"
                                  id="provincia"
                                  value={prov.id}
                                >
                                  {prov.name}
                                </option>
                              );
                            })
                          )}
                        </SelectInput>
                      </Col>
                      {loadingProv && (
                        <Col md={1} className="align-self-baseline mt-2">
                          {loadingProv && (
                            <option className="spinner-border spinner-border text-ab"></option>
                          )}
                        </Col>
                      )}
                    </Row>
                    <Row className="justify-content-start align-items-center mb-2">
                      <Col md={loadingMuni ? 5 : 6}>
                        <SelectInput
                          register={register}
                          name="cups_id_municipi"
                          label="Municipio"
                          error={errors.cups_id_municipi?.message}
                          onChange={(e) => {
                            setValue("cups_id_municipi", e.target.value);
                            fetchPoblaciones(e.target.value);
                          }}
                          disabled={!idProv || loadingMuni || !municipios || !municipios.length}
                        >
                          {loadingMuni ? (
                            <option>Cargando municipios...</option>
                          ) : (
                            municipios &&
                            municipios.map((muni) => {
                              return (
                                <option
                                  selected={!muni.id}
                                  disabled={!muni.id}
                                  key={muni.id}
                                  name="municipio"
                                  id="municipio"
                                  value={muni.id}
                                >
                                  {muni.name}
                                </option>
                              );
                            })
                          )}
                        </SelectInput>
                      </Col>
                      {loadingMuni && (
                        <Col md={1} className="align-self-baseline mt-2">
                          {loadingMuni && (
                            <option className="spinner-border spinner-border text-ab"></option>
                          )}
                        </Col>
                      )}
                      <Col md={loadingPob ? 5 : 6}>
                        <SelectInput
                          register={register}
                          name="cups_id_poblacio"
                          label="Población"
                          error={errors.cups_id_poblacio?.message}
                          disabled={
                            !parseInt(idMuni) || loadingPob || !poblaciones || !poblaciones.length
                          }
                        >
                          {loadingPob ? (
                            <option>Cargando poblaciones...</option>
                          ) : (
                            poblaciones &&
                            poblaciones.map((pobla) => {
                              return (
                                <option
                                  selected={!pobla.id}
                                  disabled={!pobla.id}
                                  key={pobla.id}
                                  name="cups_id_poblacio"
                                  id="cups_id_poblacio"
                                  value={pobla.id}
                                >
                                  {pobla.name}
                                </option>
                              );
                            })
                          )}
                        </SelectInput>
                      </Col>
                      {loadingPob && (
                        <Col md={1} className="align-self-baseline mt-2">
                          {loadingPob && (
                            <option className="spinner-border spinner-border text-ab"></option>
                          )}
                        </Col>
                      )}
                    </Row>
                    <Row className="justify-content-start align-items-center mb-2">
                      <Col lg={3} md={4} xs={4}>
                        <SelectInput
                          register={register}
                          name="cups_tv"
                          label="Tipo de vía"
                          error={errors.cups_tv?.message}
                        >
                          {tipovia &&
                            tipovia.map((via) => {
                              return (
                                <option
                                  key={via.id}
                                  name="tipovia"
                                  id="tipovia"
                                  value={via.id}
                                >
                                  {via.name}
                                </option>
                              );
                            })}
                        </SelectInput>
                      </Col>
                      <Col lg={6} md={8} xs={8}>
                        <TextInput
                          register={register}
                          name="cups_nv"
                          label="Calle"
                          error={errors.cups_nv?.message}
                        />
                      </Col>
                      <Col lg={3} md={12}>
                        <TextInput
                          register={register}
                          name="cups_pnp"
                          label="Número"
                          error={errors.cups_pnp?.message}
                        />
                      </Col>
                    </Row>
                    <Row className="justify-content-start align-items-center mb-2">
                      <Col lg={3} md={6} xs={6}>
                        <TextInput
                          register={register}
                          name="cups_es"
                          label="Escalera"
                          error={errors.cups_es?.message}
                        />
                      </Col>
                      <Col lg={3} md={6} xs={6}>
                        <TextInput
                          register={register}
                          name="cups_pt"
                          label="Planta"
                          error={errors.cups_pt?.message}
                        />
                      </Col>
                      <Col lg={3} md={6} xs={6}>
                        <TextInput
                          register={register}
                          name="cups_pu"
                          label="Puerta"
                          error={errors.cups_pu?.message}
                        />
                      </Col>
                      <Col lg={3} md={6} xs={6}>
                        <TextInput
                          register={register}
                          name="cups_dp"
                          label="C.P."
                          error={errors.cups_dp?.message}
                        />
                      </Col>
                    </Row>
                    <Row className="justify-content-start align-items-center mb-2">
                      <Col md={12}>
                        <TextInput
                          register={register}
                          name="cups_aclarador"
                          label="Aclarador"
                          error={errors.cups_aclarador?.message}
                        />
                      </Col>
                    </Row>
                  </>
                )}
                <Row className="justify-content-center">
                  <Col lg={8} md={12}>
                    <Row className="justify-content-evenly mx-5">
                      <Col className="mb-2">
                        <Button
                          className="button rounded-5 button-block"
                          type="submit"
                          disabled={!isValid || !potenciasCheck}
                        >
                          Continuar
                        </Button>
                      </Col>
                      {editMode && (
                        <Col>
                          <Button
                            className="button rounded-5 button-secondary button-block"
                            type="submit"
                            variant="secondary"
                            onClick={() => navigate("/contratos/ofertas")}
                          >
                            Volver a ofertas
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  paises: state.Auxiliares.paises,
  provincias: state.Auxiliares.provincias,
  municipios: state.Auxiliares.municipios,
  poblaciones: state.Auxiliares.poblaciones,
  tipovia: state.Auxiliares.tipovia,
  distribuidorasGas: state.Auxiliares.distribuidorasGas,
  cnaes: state.Auxiliares.cnaes,
});

export default connect(mapStateToProps, {
  get_paises,
  get_provincias,
  get_municipios,
  get_poblaciones,
  get_tipovia,
  get_distribuidoras_gas,
  get_cnaes,
  setLoader,
  removeLoader,
})(OfertaCups);
