import { combineReducers } from 'redux';
import Auth from './auth';
import Tarifas from './tarifas';
import Auxiliares from './auxiliares';
import Contratos from './contratos';
import Loader from './loader';

export default combineReducers({
    Auth,
    Tarifas,
    Auxiliares,
    Contratos,
    Loader
})