import { SET_LOADER, REMOVE_LOADER } from "./types";

export const setLoader = (mensaje) => (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: { mensaje },
  });
};

export const removeLoader = () => (dispatch) => {
  dispatch({
    type: REMOVE_LOADER,
  });
};
