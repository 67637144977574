import Layout from "../../hocs/Layout";
import { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { get_partner_by_id } from "../../services/partner";
import { get_datos_oferta_erp, set_oferta_erp } from "../../services/ofertas";
import {
  get_pagador_by_id,
  get_pagador_by_vat,
  register_pagador,
  register_partner_bank,
} from "../../services/pagador";
import { setLoader, removeLoader } from "../../redux/actions/loader";
import {
  get_paises,
  get_provincias,
  get_municipios,
  get_poblaciones,
  get_tipovia,
} from "../../redux/actions/auxiliares";
import { getBanks } from "../../services/auxiliares";
import { Button, Col, Form, Row, Container, Card } from "react-bootstrap";
import { TextInput, SelectInput } from "../../components/inputs/Inputs";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { pagadorSchema } from "../../utils/validationSchemas";

const OfertaPagador = ({
  setLoader,
  removeLoader,
  paises,
  provincias,
  municipios,
  poblaciones,
  tipovia,
  get_paises,
  get_provincias,
  get_municipios,
  get_poblaciones,
  get_tipovia,
}) => {
  const location = useLocation();
  const params = useParams();
  const sector_type = params.sector_type;
  useEffect(() => {
    const get_datos_iniciales = async () => {
      setLoader("Cargando datos iniciales");
      await get_paises();
      await get_tipovia();
      removeLoader();
      setOfertaErp(await get_datos_oferta_erp(params.oferta_id));
    };
    if (location.state) {
      setEditMode(location.state.editMode);
    }
    // setPartnerLoading(true);
    get_datos_iniciales();
  }, []);

  const navigate = useNavigate();
  const [oferta_erp, setOfertaErp] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [pagador, setPagador] = useState(null);
  const [partner, setPartner] = useState(null);
  const [loadingProv, setLoadingProv] = useState(false);
  const [loadingMuni, setLoadingMuni] = useState(false);
  const [loadingPob, setLoadingPob] = useState(false);
  const [partnerLoading, setPartnerLoading] = useState(false);

  const defaultValues = {
    bank: "",
    iban: "",
    cliente_tipo_persona: "",
    cliente_nombre: "",
    cliente_apellidos: "",
    cliente_vat: "",
    cliente_telefono_1: "",
    cliente_telefono_2: "",
    cliente_email_1: "",
    cliente_pais: "",
    cups_aclarador: "",
    partnerSepa: true,
    cups_nv: "",
    cups_pt: "",
    cups_pu: "",
    cups_es: "",
    cups_pnp: "",
    cups_dp: "",
    cups_id_pais: "",
    cups_id_provincia: "",
    cups_id_municipi: "",
    cups_id_poblacio: "",
    cups_tv: "",
    state: "iban",
    secuence: 0,
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    getValues,
    setValue,
    reset,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(pagadorSchema),
  });

  useEffect(() => {
    if (oferta_erp) {
      const getPartner = async () => {
        setPartner(await get_partner_by_id(oferta_erp.partner_id[0]));
      };
      if (oferta_erp.num_cta) {
        const iban = oferta_erp.num_cta[1].replaceAll(" ", "");
        setValue("iban", iban);
        fetchBank(iban);
      }
      getPartner();
    }
  }, [oferta_erp]);

  const setFields = (data) => {
    const partnerSepa = getValues().partnerSepa;
    reset({
      partnerSepa: partnerSepa,
      cliente_tipo_persona: data.cifnif == "NI" ? "fisica" : "juridica",
      cliente_nombre: data.first_name,
      cliente_apellidos: data.last_name,
      cliente_vat: data.vat,
      cliente_telefono_1: data.phone,
      cliente_telefono_2: data.mobile,
      cliente_email_1: data.email,
      cliente_pais: data.country_id[0],
      cups_aclarador: data.aclarador,
      cups_nv: data.nv,
      cups_pt: data.pt,
      cups_pu: data.pu,
      cups_es: data.es,
      cups_pnp: data.pnp,
      cups_dp: data.zip,
      cups_id_pais: data.country[0],
      cups_id_provincia: data.state_id[0],
      cups_id_municipi: data.id_municipi[0],
      cups_id_poblacio: data.id_poblacio ? data.id_poblacio[0] : "",
      cups_tv: data.tv[0],
    });
    const setLocation = async () => {
      setLoader("Cargando cliente");
      await get_provincias(data.country[0]);
      await get_municipios(data.state_id[0]);
      await get_poblaciones(data.id_municipi[0]);
      // setValue("cups_id_provincia", data.state_id[0]);
      // setValue("cups_id_municipi", data.id_municipi[0]);
      // setValue("cups_id_poblacio", data.id_poblacio[0]);
      setPartnerLoading(false);
      removeLoader();
    };
    setLocation();
  };

  useEffect(() => {
    if (partner && !pagador) {
      setPartnerLoading(true);
      if (
        //Si no hay pagador registrado (1er ingreso) o si el pagador es el mismo que el partner
        oferta_erp.pagador_autorizacion_sepa === false ||
        oferta_erp.partner_id[0] === oferta_erp.pagador_autorizacion_sepa[0]
      ) {
        setFields(partner);
      } else {
        handleChangeSepa(false);
        const getPagador = async () => {
          setPagador(
            await get_pagador_by_id(oferta_erp.pagador_autorizacion_sepa[0])
          );
        };
        getPagador();
      }
    }
  }, [partner]);

  useEffect(() => {
    if (pagador && partnerLoading) {
      setFields(pagador);
    }
  }, [pagador]);

  const changeIban = (event) => {
    // Evita que se agreguen espacios al IBAN
    if (!event.key.trim()) {
      event.preventDefault();
    }
  };

  const fetchBank = async (iban) => {
    if (iban.length === 24) {
      try {
        const banco = await getBanks(iban);
        setValue("bank", { name: banco[0].name, id: banco[0].id });
      } catch (error) {}
    }
  };

  const fetchProvincias = async (paisId) => {
    if (parseInt(paisId) && !partnerLoading) {
      setLoadingProv(true);
      setValue("cups_id_provincia", "");
      setValue("cups_id_municipi", "");
      setValue("cups_id_poblacio", "");
      await get_provincias(paisId);
      setLoadingProv(false);
    }
  };

  const fetchMunicipios = async (provId) => {
    if (parseInt(provId) && !partnerLoading) {
      setLoadingMuni(true);
      setValue("cups_id_municipi", "");
      setValue("cups_id_poblacio", "");
      await get_municipios(provId);
      setLoadingMuni(false);
    }
  };

  const fetchPoblaciones = async (muniId) => {
    if (parseInt(muniId) && !partnerLoading) {
      setLoadingPob(true);
      await get_poblaciones(muniId);
      setValue("cups_id_poblacio", "");
      setLoadingPob(false);
    }
  };

  const onSearchPartner = async () => {
    const data = getValues();
    const partnerRes = await get_pagador_by_vat(data.cliente_vat);
    if (!partnerRes) {
      reset({
        ...defaultValues,
        cliente_vat: data.cliente_vat,
        partnerSepa: data.partnerSepa,
      });
    } else {
      setPartnerLoading(true);
      setPartner(partnerRes);
    }
  };

  const onSubmit = async () => {
    const data = getValues();
    let pagadorId = partner.id;
    let datos_bank = {
      city: data.cups_id_municipi,
      zip: data.cups_dp,
      sequence: 0,
      country_id: data.cliente_pais,
      state: "iban",
      street: data.cups_nv,
      state_id: data.cups_id_provincia,
      partner_id: partner.id, //es el dueño del contrato
      bank: data.bank.id,
      iban: data.iban,
      acc_country_id: data.cups_id_pais,
      owner_address_id: partner.address[0],
      owner_id: partner.id, //Owner es el dueño de la cuenta bancaria
      owner_name: partner.name,
    };
    if (!partnerSepa) {
      const datos = {
        cliente_tipo_persona: data.cliente_tipo_persona,
        cliente_nombre: data.cliente_nombre,
        cliente_apellidos: data.cliente_apellidos,
        cliente_vat: data.cliente_vat,
        cliente_telefono_1: data.cliente_telefono_1,
        cliente_telefono_2: data.cliente_telefono_2,
        cliente_email_1: data.cliente_email_1,
        country_id: data.cliente_pais,
        cups_id_provincia: data.cups_id_provincia,
        cups_id_municipi: data.cups_id_municipi,
        cups_id_poblacio: data.cups_id_poblacio,
        cups_aclarador: data.cups_aclarador,
        cups_tv: data.cups_tv,
        cups_nv: data.cups_nv,
        cups_pt: data.cups_pt,
        cups_pu: data.cups_pu,
        cups_es: data.cups_es,
        cups_pnp: data.cups_pnp,
        cups_dp: data.cups_dp,
        cups_cpo: null,
        cups_cpa: null,
      };
      const pagadorRes = await register_pagador(datos);
      pagadorId = pagadorRes.id;
      datos_bank.owner_id = pagadorRes.id;
      datos_bank.owner_address_id = pagadorRes.address[0];
      datos_bank.owner_name = pagadorRes.name;
    }
    const partnerBank = await register_partner_bank(
      params.oferta_id,
      datos_bank
    );
    const datos_oferta = {
      pagador_autorizacion_sepa: pagadorId,
      entidad_bancaria: data.bank.name,
      direccion_clte_sepa: partner.address[0],
      num_cta: partnerBank.id,
    };
    await set_oferta_erp(params.oferta_id, datos_oferta, sector_type);
    navigate("/" + sector_type + "/cups/" + params.oferta_id);
  };

  const handleChangeSepa = (value) => {
    setValue("partnerSepa", value);
    if (!value) {
      reset({ partnerSepa: value });
    }
    else{
      setFields(partner)
    }
  };

  const idPais = watch("cups_id_pais");
  const idProv = watch("cups_id_provincia");
  const idMuni = watch("cups_id_municipi");
  const partnerSepa = watch("partnerSepa");
  const tipoPersona = watch("cliente_tipo_persona");
  const disableSearchPartner = watch("cliente_vat");

  return (
    <Layout lg="6" md="8" title="Datos del pagador">
      <Card className="card-label-corner">
        {/* <Card.Header
          className={
            sector_type === "electrico" ? "ab-primary-elec" : "ab-primary-gas"
          }
        >
          <Row className="align-items-center justify-content-center my-1">
            <Col className="text-center" md={{ span: 9, offset: 1 }}>
              <h1 className="text-white form-label m-0">Datos del pagador</h1>
            </Col>
            <Col md="2">
              <img
                style={{ backgroundColor: "white", borderRadius: "50%" }}
                src={sector_type === "electrico" ? logo_electricidad : logo_gas}
                className="img-fluid p-2"
                width="40"
                title={sector_type === "electrico" ? "Electricidad" : "Gas"}
                alt={sector_type === "electrico" ? "Electricidad" : "Gas"}
              />
            </Col>
          </Row>
        </Card.Header> */}
        <Card.Body>
          <Row className="justify-content-center align-items-center mt-2">
            <Col>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <DevTool control={control} />
                <Row className="justify-content-start align-items-center mb-2">
                  <Col lg={6} md={12}>
                    <TextInput
                      register={register}
                      name="iban"
                      label="IBAN"
                      onKeyDown={(e) => changeIban(e)}
                      onChange={(e) => {
                        setValue("iban", e.target.value);
                        fetchBank(e.target.value);
                      }}
                      error={errors.iban?.message}
                      hint="No ingresar espacios"
                    />
                  </Col>
                  <Col lg={6} md={12}>
                    <TextInput
                      register={register}
                      name="bank.name"
                      label="Entidad bancaria"
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col md="auto">
                    <Controller
                      control={control}
                      name="partnerSepa"
                      render={({ field: { onBlur, value } }) => (
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicCheckbox"
                        >
                          <Form.Check
                            defaultChecked
                            name="partnerSepa"
                            type="switch"
                            onChange={(e) => {
                              handleChangeSepa(e.target.checked);
                            }}
                            onBlur={onBlur}
                            value={value}
                            label="Mismo titular y pagador"
                          />
                        </Form.Group>
                      )}
                    />
                  </Col>
                </Row>
                {partnerSepa === false && (
                  <>
                    <Row className="justify-content-start align-items-center">
                      <Col lg={4} md={6} sm={8}>
                        <TextInput
                          register={register}
                          name="cliente_vat"
                          label="Documento"
                          error={errors.cliente_vat?.message}
                        />
                      </Col>
                      <Col md={6} sm={4} className="align-self-baseline mt-2">
                        <Button
                          className="button rounded-5"
                          onClick={onSearchPartner}
                          disabled={!disableSearchPartner}
                        >
                          Buscar Cliente
                        </Button>
                      </Col>
                      <Col lg="auto" md={7}>
                    <SelectInput
                      register={register}
                      name="cliente_pais"
                      label="Nacionalidad"
                      error={errors.cups_id_pais?.message}
                      onChange={(e) => {
                        setValue("cliente_pais", e.target.value);
                      }}
                    >
                      {paises &&
                        paises.map((pais) => {
                          return (
                            <option
                              selected={!pais.id}
                              disabled={!pais.id}
                              key={pais.id}
                              name="pais"
                              id="pais"
                              value={pais.id}
                            >
                              {pais.name}
                            </option>
                          );
                        })}
                    </SelectInput>
                  </Col>
                    </Row>
                    <Row className="justify-content-start align-items-center">
                      <Col lg={4} md={7}>
                        <SelectInput
                          register={register}
                          name="cliente_tipo_persona"
                          label="Tipo de persona"
                          error={errors.cliente_tipo_persona?.message}
                        >
                          <option key="null" value="" disabled>
                            Seleccione tipo de persona
                          </option>
                          <option value="fisica">Física</option>
                          <option value="juridica">Jurídica</option>
                        </SelectInput>
                      </Col>
                      {tipoPersona === "fisica" && (
                        <Col lg={4} md={6}>
                          <TextInput
                            register={register}
                            name="cliente_nombre"
                            label="Nombre"
                            error={errors.cliente_nombre?.message}
                          />
                        </Col>
                      )}
                      <Col lg={4} md={6}>
                        <TextInput
                          register={register}
                          name="cliente_apellidos"
                          label={
                            tipoPersona !== "fisica" ? "Empresa" : "Apellido"
                          }
                          error={errors.cliente_apellidos?.message}
                        />
                      </Col>
                    </Row>
                    <Row className="justify-content-start align-items-center">
                      <Col lg={4} md={6}>
                        <TextInput
                          register={register}
                          name="cliente_email_1"
                          label="Email"
                          error={errors.cliente_email_1?.message}
                        />
                      </Col>
                      <Col lg={4} md={6}>
                        <TextInput
                          register={register}
                          name="cliente_telefono_1"
                          label="Teléfono"
                          error={errors.cliente_telefono_1?.message}
                        />
                      </Col>
                      <Col lg={4} md={6}>
                        <TextInput
                          register={register}
                          name="cliente_telefono_2"
                          label="Teléfono móvil"
                          error={errors.cliente_telefono_2?.message}
                        />
                      </Col>
                    </Row>
                    <Row className="justify-content-start align-items-center">
                      <Col md={6}>
                        <SelectInput
                          register={register}
                          name="cups_id_pais"
                          label="País"
                          error={errors.cups_id_pais?.message}
                          onChange={(e) => {
                            setValue("cups_id_pais", e.target.value);
                            fetchProvincias(e.target.value);
                          }}
                        >
                          {paises &&
                            paises.map((pais) => {
                              return (
                                <option
                                  selected={!pais.id}
                                  disabled={!pais.id}
                                  key={pais.id}
                                  name="pais"
                                  id="pais"
                                  value={pais.id}
                                >
                                  {pais.name}
                                </option>
                              );
                            })}
                        </SelectInput>
                      </Col>
                      <Col md={loadingProv ? 5 : 6}>
                        <SelectInput
                          register={register}
                          name="cups_id_provincia"
                          label="Provincia"
                          error={errors.cups_id_provincia?.message}
                          onChange={(e) => {
                            setValue("cups_id_provincia", e.target.value);
                            fetchMunicipios(e.target.value);
                          }}
                          disabled={!idPais || loadingProv || !provincias || !provincias.length}
                        >
                          {loadingProv ? (
                            <option>Cargando provincias...</option>
                          ) : (
                            provincias &&
                            provincias.map((prov) => {
                              return (
                                <option
                                  selected={!prov.id}
                                  disabled={!prov.id}
                                  key={prov.id}
                                  name="provincia"
                                  id="provincia"
                                  value={prov.id}
                                >
                                  {prov.name}
                                </option>
                              );
                            })
                          )}
                        </SelectInput>
                      </Col>
                      {loadingProv && (
                        <Col md={1} className="align-self-baseline mt-2">
                          {loadingProv && (
                            <option className="spinner-border spinner-border text-ab"></option>
                          )}
                        </Col>
                      )}
                    </Row>
                    <Row className="justify-content-start align-items-center">
                      <Col md={loadingMuni ? 5 : 6}>
                        <SelectInput
                          register={register}
                          name="cups_id_municipi"
                          label="Municipio"
                          error={errors.cups_id_municipi?.message}
                          onChange={(e) => {
                            setValue("cups_id_municipi", e.target.value);
                            fetchPoblaciones(e.target.value);
                          }}
                          disabled={!idProv || loadingMuni || !municipios || !municipios.length}
                        >
                          {loadingMuni ? (
                            <option>Cargando municipios...</option>
                          ) : (
                            municipios &&
                            municipios.map((muni) => {
                              return (
                                <option
                                  selected={!muni.id}
                                  disabled={!muni.id}
                                  key={muni.id}
                                  name="municipio"
                                  id="municipio"
                                  value={muni.id}
                                >
                                  {muni.name}
                                </option>
                              );
                            })
                          )}
                        </SelectInput>
                      </Col>
                      {loadingMuni && (
                        <Col md={1} className="align-self-baseline mt-2">
                          {loadingMuni && (
                            <option className="spinner-border spinner-border text-ab"></option>
                          )}
                        </Col>
                      )}
                      <Col md={loadingPob ? 5 : 6}>
                        <SelectInput
                          register={register}
                          name="cups_id_poblacio"
                          label="Población"
                          error={errors.cups_id_poblacio?.message}
                          disabled={
                            !parseInt(idMuni) || loadingPob || !poblaciones || !poblaciones.length
                          }
                        >
                          {loadingPob ? (
                            <option>Cargando poblaciones...</option>
                          ) : (
                            poblaciones &&
                            poblaciones.map((pobla) => {
                              return (
                                <option
                                  selected={!pobla.id}
                                  disabled={!pobla.id}
                                  key={pobla.id}
                                  name="cups_id_poblacio"
                                  id="cups_id_poblacio"
                                  value={pobla.id}
                                >
                                  {pobla.name}
                                </option>
                              );
                            })
                          )}
                        </SelectInput>
                      </Col>
                      {loadingPob && (
                        <Col md={1} className="align-self-baseline mt-2">
                          {loadingPob && (
                            <option className="spinner-border spinner-border text-ab"></option>
                          )}
                        </Col>
                      )}
                    </Row>
                    <Row className="justify-content-start align-items-center">
                      <Col lg={3} md={4} xs={4}>
                        <SelectInput
                          register={register}
                          name="cups_tv"
                          label="Tipo de vía"
                          error={errors.cups_tv?.message}
                        >
                          {tipovia &&
                            tipovia.map((via) => {
                              return (
                                <option
                                  key={via.id}
                                  name="tipovia"
                                  id="tipovia"
                                  value={via.id}
                                >
                                  {via.name}
                                </option>
                              );
                            })}
                        </SelectInput>
                      </Col>
                      <Col lg={6} md={8} xs={8}>
                        <TextInput
                          register={register}
                          name="cups_nv"
                          label="Calle"
                          error={errors.cups_nv?.message}
                        />
                      </Col>
                      <Col lg={3} md={12}>
                        <TextInput
                          register={register}
                          name="cups_pnp"
                          label="Número"
                          error={errors.cups_pnp?.message}
                        />
                      </Col>
                    </Row>
                    <Row className="justify-content-start align-items-center">
                      <Col lg={3} md={6} xs={6}>
                        <TextInput
                          register={register}
                          name="cups_es"
                          label="Escalera"
                          error={errors.cups_es?.message}
                        />
                      </Col>
                      <Col lg={3} md={6} xs={6}>
                        <TextInput
                          register={register}
                          name="cups_pt"
                          label="Planta"
                          error={errors.cups_pt?.message}
                        />
                      </Col>
                      <Col lg={3} md={6} xs={6}>
                        <TextInput
                          register={register}
                          name="cups_pu"
                          label="Puerta"
                          error={errors.cups_pu?.message}
                        />
                      </Col>
                      <Col lg={3} md={6} xs={6}>
                        <TextInput
                          register={register}
                          name="cups_dp"
                          label="C.P."
                          error={errors.cups_dp?.message}
                        />
                      </Col>
                    </Row>
                    <Row className="justify-content-start align-items-center">
                      <Col md={12}>
                        <TextInput
                          register={register}
                          name="cups_aclarador"
                          label="Aclarador"
                          error={errors.cups_aclarador?.message}
                        />
                      </Col>
                    </Row>
                  </>
                )}

                <Row className="justify-content-center">
                  <Col lg={8} md={12}>
                    <Row className="justify-content-evenly mx-5">
                      <Col className="mb-2">
                        <Button
                          className="button rounded-5 button-block"
                          type="submit"
                          disabled={!isValid}
                        >
                          Continuar
                        </Button>
                      </Col>
                      {editMode && (
                        <Col>
                          <Button
                            className="button rounded-5 button-secondary button-block"
                            variant="secondary"
                            onClick={() => navigate("/contratos/ofertas")}
                          >
                            Volver a ofertas
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  paises: state.Auxiliares.paises,
  provincias: state.Auxiliares.provincias,
  municipios: state.Auxiliares.municipios,
  poblaciones: state.Auxiliares.poblaciones,
  tipovia: state.Auxiliares.tipovia,
});

export default connect(mapStateToProps, {
  get_paises,
  get_provincias,
  get_municipios,
  get_poblaciones,
  get_tipovia,
  setLoader,
  removeLoader,
})(OfertaPagador);
