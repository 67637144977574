import {
  GET_PAIS_SUCCESS,
  GET_PROVINCIA_SUCCESS,
  GET_MUNICIPIO_SUCCESS,
  GET_POBLACION_SUCCESS,
  GET_TIPOVIA_SUCCESS,
  GET_DISTRIBUIDORAS_GAS_SUCCESS,
  GET_CNAES_SUCCESS,
} from "../actions/types";

const initialState = {
  paisId: null,
  paises: null,
  provId: null,
  provincias: null,
  muniId: null,
  municipios: null,
  poblaciones: null,
  tipovia: null,
  distribuidorasGas: null,
  cnaes: null,
};

export default function Auxiliares(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PAIS_SUCCESS:
      return {
        ...state,
        paises: payload,
      };
    case GET_PROVINCIA_SUCCESS:
      return {
        ...state,
        provincias: payload.provincias,
        paisId: payload.pais,
      };
    case GET_MUNICIPIO_SUCCESS:
      return {
        ...state,
        municipios: payload.municipios,
        provId: payload.provincia,
      };
    case GET_POBLACION_SUCCESS:
      return {
        ...state,
        poblaciones: payload.poblaciones,
        muniId: payload.municipio,
      };
    case GET_TIPOVIA_SUCCESS:
      return {
        ...state,
        tipovia: payload,
      };
    case GET_DISTRIBUIDORAS_GAS_SUCCESS:
      return {
        ...state,
        distribuidorasGas: payload,
      };
    case GET_CNAES_SUCCESS:
      return {
        ...state,
        cnaes: payload,
      };
    default:
      return state;
  }
}
