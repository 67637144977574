import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal, Row, Col, Button, Table, Stack } from "react-bootstrap";
import { get_archivos, get_archivo_by_id, delete_archivo } from "../../services/contratos";
import { CiWarning } from "react-icons/ci";
import { MdDeleteForever, MdDownload } from "react-icons/md";

function GestionArchivos({ contrato, show, close }) {
  const [loading, setLoading] = useState(false);
  const [archivos, setArchivos] = useState(false);

  useEffect(() => {
    if (show) {
      const getArchivos = async () => {
        setLoading(true);
        setArchivos(await get_archivos(contrato.id,contrato.sector_type));
        setLoading(false);
      };
      getArchivos();
    }
  }, [show]);

  const cerrar = () => {
    close(false);
  };

  const descargarArchivo = async (attach_id) => {
    try {
      const archivo = await get_archivo_by_id(attach_id);
      Object.keys(archivo).forEach((arch) => {
        let pdfWindow = window.open("");
        pdfWindow.document.write(
          "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
            encodeURI(archivo[arch]) +
            "'></iframe>"
        );
        pdfWindow.document.title = "AB Energía - Agentes";
      })
    } catch (error) {}
  };

  const eliminarArchivo = async (attach_id) => {
    try {
      await delete_archivo(attach_id);
    } catch (error) {}finally{
      getArchivos()
    }
  };

  const getArchivos = async () => {
    setLoading(true);
    setArchivos(await get_archivos(contrato.id,contrato.sector_type));
    setLoading(false);
  };

  return (
    <Modal
      show={show}
      size="lg"
      backdrop="static"
      className="border border-0"
      centered
    >
      <Modal.Header className="ab-primary justify-content-between align-items-center">
        <Col md="auto">
          <h2 className="text-white my-0">Gestión de archivos</h2>
        </Col>
        <Col md="auto">
          <h2 className="text-white my-0">Nº {contrato.name}</h2>
        </Col>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center align-items-center">
          {loading ? (
            <Col
              style={{ height: "40vh" }}
              className="justify-content-center d-flex"
            >
              <Stack direction="horizontal" gap={2}>
                <span className="spinner-border spinner-border text-ab"></span>
                <h3 className="mb-0">Cargando archivos</h3>
              </Stack>
            </Col>
          ) : archivos ? (
            <Col
              style={{ height: "40vh", overflow: "scroll" }}
              className="px-2"
            >
              <Table
                maxHeight="20px"
                striped
                bordered
                hover
                size="lg"
                responsive
                className="overflow-hidden"
              >
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Archivo</th>
                    <th className={"w-25"}>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {archivos &&
                    archivos.map((archivo) => {
                      return (
                        <tr key={archivo.id} className="align-middle">
                          <td>{archivo.name}</td>
                          <td>{archivo.datas_fname}</td>
                          <td>
                            <Row className="align-items-center justify-content-center my-1">
                              <Col md="auto" style={{ paddingRight: 0 + "px" }}>
                                <Button
                                  size="lg"
                                  className="ab-secondary button rounded-pill"
                                  title="Descargar archivo"
                                  onClick={() => descargarArchivo(archivo.id)}
                                >
                                  <MdDownload />
                                </Button>
                              </Col>
                              <Col md="auto">
                                <Button
                                  size="lg"
                                  className="button-danger button rounded-pill"
                                  title="Eliminar archivo"
                                  onClick={() => eliminarArchivo(archivo.id)}
                                >
                                  <MdDeleteForever />
                                </Button>
                              </Col>
                            </Row>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Col>
          ) : (
            <Col md="12" style={{ height: "40vh" }} className="pt-4">
              <Row className="justify-content-center">
                <Col md="auto">
                  <h1 className="error-title d-flex justify-content-center">
                    <CiWarning />
                  </h1>
                  <h1 className="text-gray">No hay archivos disponibles.</h1>
                </Col>
              </Row>
              <Row className="justify-content-center mt-2">
                <Col md="auto">
                  <Button
                    className="button rounded-5 button-block"
                    variant="secondary"
                    onClick={() => getArchivos()}
                  >
                    Reintentar
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Col md="auto">
          <Button
            className="button rounded-5 button-secondary border-0"
            variant="secondary"
            onClick={cerrar}
          >
            Cerrar
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}

export default connect()(GestionArchivos);
