import { Link, Navigate } from "react-router-dom";
import Layout from "../hocs/Layout";
import { connect } from "react-redux";
import { useState, React } from "react";
import logo_electricidad from "../media/img/Bulb_final.png";
import logo_gas from "../media/img/Gas_final.png";
import logo_cartera from "../media/img/Cartera_final.png";
import logo_tarifario from "../media/img/Tarifario_final.png";
import { Col, Row, Card, Button, Image } from "react-bootstrap";
import Carteras from "../components/modals/Carteras";
import TARIFARIO_ELECTRICIDAD from "../media/TARIFARIO_ELECTRICIDAD.pdf"
import TARIFARIO_GAS from "../media/TARIFARIO_GAS.pdf"

const Home = ({ isAuthenticated }) => {
  const [showCartera, setShowCartera] = useState(false);
  const abrirCartera = () => {
    setShowCartera(true);
  };
    
  return (
    <Layout lg={8} md={10} className="mt-5">
      {isAuthenticated ? (
        <Row className="mt-5 align-items-center justify-content-center">
          <Col className="mt-5" lg={4} md={8}>
            <Card>
              <Card.Title className="pt-2 ab-primary m-0">
                <h2 className="text-white">Contratación</h2>
              </Card.Title>
              <Card.Body>
                <Row
                  className="align-items-center justify-content-center p-0 m-0"
                  style={{ height: "25vh" }}
                >
                  <Col md="6" xs="6">
                    <Link to="/electrico/tarifas">
                      <Image
                        fluid
                        src={logo_electricidad}
                        alt="Electricidad"
                        width={"40%"}
                        className="my-4"
                      />
                      <Button className="button button-block fw-bold mt-lg-4 mt-md-0 btn-home">
                        ELECTRICIDAD
                      </Button>
                    </Link>
                  </Col>
                  <Col md="6" xs="6">
                    <Link to="/gas/tarifas">
                      <Image
                        fluid
                        src={logo_gas}
                        alt="Gas"
                        width={"40%"}
                        className="my-4"
                      />
                      <Button className="button button-block fw-bold mt-lg-4 mt-md-0 btn-home">
                        GAS
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={8} className="mt-5">
            <Card>
              <Card.Title className="pt-2 ab-primary m-0">
                <h2 className="text-white">Mi cartera</h2>
              </Card.Title>
              <Card.Body>
                <Row
                  className="align-items-center justify-content-center"
                  style={{ height: "25vh" }}
                >
                  <Col>
                    <img
                      src={logo_cartera}
                      onClick={() => abrirCartera()}
                      className="img-fluid p-4"
                      width="120"
                      alt="Cartera"
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={8} className="mt-5">
            <Card>
              <Card.Title className="pt-2 ab-primary m-0">
                <h2 className="text-white">Tarifario</h2>
              </Card.Title>
              <Card.Body>
                <Row style={{ height: "25vh" }}>
                  <Row className="align-items-center justify-content-center">
                    <Col>
                      <img
                        src={logo_tarifario}
                        className="img-fluid my-4"
                        width={"20%"}
                        alt="Tarifario"
                      />
                      <Row className="align-items-center justify-content-around mt-4">
                        <Col md="6" xs="6">
                          <Button className="button button-block fw-bold btn-home">
                            <a
                              href={TARIFARIO_ELECTRICIDAD}
                              target="_blank"
                              className="link"
                            >
                              ELECTRICIDAD
                            </a>
                          </Button>
                        </Col>
                        <Col md="6" xs="6">
                          <Button className="button button-block fw-bold btn-home">
                            <a
                              href={TARIFARIO_GAS}
                              target="_blank"
                              className="link"
                            >
                              GAS
                            </a>
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Carteras show={showCartera} close={setShowCartera} />
        </Row>
      ) : (
        <Navigate to="/login" />
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.Auth.isAuthenticated,
});

export default connect(mapStateToProps)(Home);
