import http from "../utils/http";
import { toast } from "react-toastify";

export const get_tarifas = async (
  sector_type,
  tarifa_oferta,
  mode_facturacio,
  margen_potencia,
  first_name,
) => {
  try {
    const res = await http.get(`/erp/tarifas/${sector_type}`, {
      params: { tarifa_oferta, mode_facturacio, margen_potencia, first_name },
    });
    if (res.status === 200) {
      res.data.tarifas_erp.unshift({id: 0,value: "",name: "Seleccione la tarifa"});
      return res.data.tarifas_erp;
    } else {
      toast.info("No hay tarifas disponibles.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  } catch (err) {
    toast.error("Error al cargar las tarifas.", {
      position: toast.POSITION.TOP_CENTER,
    });
  }
};
