import {
  GET_PAIS_SUCCESS,
  GET_PROVINCIA_SUCCESS,
  GET_MUNICIPIO_SUCCESS,
  GET_POBLACION_SUCCESS,
  GET_TIPOVIA_SUCCESS,
  GET_DISTRIBUIDORAS_GAS_SUCCESS,
  GET_CNAES_SUCCESS,
} from "./types";

import { store } from "../../store";
import {
  getPaises,
  getProvincias,
  getMunicipios,
  getPoblaciones,
  getTipovias,
  getDistribuidorasGas,
  getCnaeList
} from "../../services/auxiliares";
const auxiliares = store.getState().Auxiliares

export const get_paises = () => {
  return async (dispatch) => {
    if (auxiliares.paises) {
      return;
    }
    const paises = await getPaises();
    if (paises) {
      dispatch({
        type: GET_PAIS_SUCCESS,
        payload: paises,
      });
    }
  };
};

export const get_provincias = (country_id) => {
  return async (dispatch) => {
    if (auxiliares.paisId === country_id) {
      return;
    }
    const provincias = await getProvincias(country_id);
    if (provincias) {
      dispatch({
        type: GET_PROVINCIA_SUCCESS,
        payload: { provincias, pais: country_id },
      });
    }
  };
};

export const get_municipios = (state) => {
  return async (dispatch) => {
    if (auxiliares.provId === state) {
      return;
    }
    const municipios = await getMunicipios(state);
    if (municipios) {
      dispatch({
        type: GET_MUNICIPIO_SUCCESS,
        payload: { municipios, provincia: state },
      });
    }
  };
};

export const get_poblaciones = (municipi_id) => {
  return async (dispatch) => {
    if (auxiliares.muniId === municipi_id) {
      return;
    }
    const poblaciones = await getPoblaciones(municipi_id);
    if (poblaciones) {
      dispatch({
        type: GET_POBLACION_SUCCESS,
        payload: { poblaciones, municipio: municipi_id },
      });
    }
  };
};

export const get_tipovia = () => {
  return async (dispatch) => {
    if (auxiliares.tipovia) {
      return;
    }
    const tipoVias = await getTipovias();
    if (tipoVias) {
      dispatch({
        type: GET_TIPOVIA_SUCCESS,
        payload: tipoVias,
      });
    }
  };
};

export const get_distribuidoras_gas = () => {
  return async (dispatch) => {
    if (auxiliares.distribuidorasGas) {
      return;
    }
    const distribuidorasGas = await getDistribuidorasGas();
    if (distribuidorasGas) {
      dispatch({
        type: GET_DISTRIBUIDORAS_GAS_SUCCESS,
        payload: distribuidorasGas,
      });
    }
  };
};

export const get_cnaes = () => {
  return async (dispatch) => {
    if (auxiliares.cnaes) {
      return;
    }
    const cnaes = await getCnaeList();
    if (cnaes) {
      dispatch({
        type: GET_CNAES_SUCCESS,
        payload: cnaes,
      });
    }
  };
};