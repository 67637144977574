import http from "../utils/http";
import { toast } from "react-toastify";
import { store } from "../store";
import { removeLoader, setLoader } from "../redux/actions/loader";

export const get_partner_by_vat = async (documento) => {
  try {
    store.dispatch(setLoader("Buscando cliente"));
    const res = await http.get(`/erp/partner`, { params: { documento } });
    return res.data.partner;
  } catch (err) {
    toast.error("Error. El cliente no existe.", {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};

export const get_partner_by_id = async (partner_id) => {
  try {
    store.dispatch(setLoader("Buscando cliente"));
    const res = await http.get(`/erp/partner`, { params: { partner_id } });
    return res.data.partner;
  } catch (err) {
    toast.error("Error. El cliente no existe.", {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};

export const find_or_create_partner = async (datos) => {
  try {
    store.dispatch(setLoader("Creando cliente"));
    const res = await http.post(`/erp/partner`, datos);
    return res.data.partner;
  } catch (err) {
    toast.error("Error al crear cliente.", {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};

export const set_partner_erp = async (partner_id, datos) => {
  const body = { partner_id, datos };
  try {
    store.dispatch(setLoader("Guardando cliente"));
    const res = await http.put(`/erp/partner`, body);
    return res.data.partner;
  } catch (err) {
    toast.error("Error al modificar cliente.", {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};
