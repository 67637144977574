import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import React, { useState } from "react";
import "./navBar.css";
import Logo from "../../media/img/abenergia-logo.png";
import { FaUser } from "react-icons/fa";
import { Navigate } from "react-router";
import { Container, NavDropdown, Offcanvas, Row, Col, Stack, Button, NavItem } from "react-bootstrap";
import Carteras from "../modals/Carteras";
import { connect } from "react-redux";
import { logout } from "../../redux/actions/auth";
import GestionSips from "../../components/modals/GestionSips";

const NavBar = ({ isAuthenticated, logout, title = null }) => {
  const [showCartera, setShowCartera] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [showGestionSips, setShowGestionSips] = useState(false);

  const logoutHandler = () => {
    logout();
    setRedirect(true);
  };

  const openGestionSips= () => {
    setShowGestionSips(true);
  };


  if (redirect) {
    window.location.reload(false);
    return <Navigate to="/login" />;
  }

  return (
    <>
      <Navbar
        className="content-fluid mb-3 h-20px bg-gradient p-0 m-0 ml-auto"
        sticky="top"
      >
        <Container
          fluid
          className="row sticky-top justify-content-center align-items-center ab-gradient m-0 p-0"
        >
          <Navbar.Brand className="col-auto bg-white brand-radius" href="/">
            <img src={Logo} className="img-fluid" alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle
            className="col"
            aria-controls={`offcanvasNavbar-expand-$sm`}
          />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-$sm`}
            aria-labelledby={`offcanvasNavbarLabel-expand-$sm`}
            placement="end"
          >
            <Row className="align-items-center">
              <Col>
                <Navbar.Text className="d-md-inline d-sm-none d-none">
                  <Row className="justify-content-center">
                    <Col md="6" className="justify-content-center d-flex navbar-title">
                      <h1 className="text-white">{title}</h1>
                    </Col>
                  </Row>
                </Navbar.Text>
              </Col>
              <Col md="auto">
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-$sm`}>
                    AB Energia
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  {isAuthenticated && (
                    <Nav className="justify-content-end flex-grow-1 pe-3">
                      <NavItem>
                        <Button
                          className="button"
                          onClick={() =>openGestionSips()}
                          title="Sips"
                        >
                          <Stack direction="horizontal" gap={3}>
                          Sips
                          </Stack>
                        </Button>
                      </NavItem>
                      <Nav.Link
                        onClick={() => setShowCartera(true)}
                        className="text-white"
                      >
                        Mi cartera
                      </Nav.Link>

                      <NavDropdown
                        align="end"
                        title={<FaUser />}
                        id={`offcanvasNavbarDropdown-expand-$sm`}
                      >
                        <NavDropdown.Item href="/profile">
                          Mi perfil
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick={logoutHandler}>
                          Cerrar sesión
                        </NavDropdown.Item>
                      </NavDropdown>
                    </Nav>
                  )}
                </Offcanvas.Body>
              </Col>
            </Row>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      <Carteras show={showCartera} close={setShowCartera} />
      <GestionSips show={showGestionSips} close={setShowGestionSips} />
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.Auth.isAuthenticated,
});

export default connect(mapStateToProps, { logout })(NavBar);
