import Layout from "../../hocs/Layout";
import { Button, Col, Row, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Error404 = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <Container className="w-75 text-center">
        <Row className="justify-content-center mt-5">
          <Col className="align-items-start mt-5">
            <Row className="mb-5">
              <Col>
                <h1 className="error-title">
                  404
                </h1>
                <h1 className="text-gray">Página no encontrada</h1>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col className="text-black">
                <p className="mb-0">
                  Lo sentimos, la página intentas acceder no existe o fue
                  movida.
                </p>
                <p>Prueba volver a la página de inicio.</p>
              </Col>
            </Row>
            <Row className="justify-content-center mt-2">
              <Col md="auto">
                <Button
                  className="button rounded-5 button-block"
                  type="submit"
                  variant="secondary"
                  onClick={() => navigate("/")}
                >
                  Volver al inicio
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Error404;
