import {
    GET_CONTRATOS_SUCCESS,
    GET_CONTRATOS_FAIL,
    CONTRATO_SUCCESS,
    CONTRATO_FAIL,   
} from '../actions/types'

const initialState = {
    contrato_gas: null,
    contrato_elec: null,
    contratos: null,

}

export default function Contrato(state = initialState, action){
    const { type, payload } = action;

    switch(type) {
        case GET_CONTRATOS_SUCCESS:
            return {
                ...state,
                contratos: payload.contratos,
            }
        case GET_CONTRATOS_FAIL:
            return {
                ...state,
                contratos: null,
                
            }
        case CONTRATO_SUCCESS:
            return {
                ...state,
                contrato_gas: payload.contrato_gas,
                contrato_elec: payload.contrato_elec,
            }
        case CONTRATO_FAIL:
            return {
                ...state,
                contrato_gas: null,
                contrato_elec: null,
            }
        default:
            return state
    }
}

