import http from "../utils/http";
import { toast } from "react-toastify";
import { store } from "../store";
import { removeLoader, setLoader } from "../redux/actions/loader";

export const find_or_create_cups = async (params) => {
  let datos = { datos: params.dataCups }
  if(params.oferta_id){
    datos.oferta_id = params.oferta_id
    datos.tarifa_oferta = params.tarifa_oferta
  } 
  try {
    store.dispatch(setLoader("Creando CUPS"));
    const res = await http.post(`/erp/cups`, { ...datos }, {
      params: { find_or_create_cups: "find_or_create_cups" },
    });
    return res.data.cups;
  } catch (err) {
    const mensaje = err.response.data.error;
    toast.error("Error al crear el CUPS:" + mensaje, {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};

export const btn_import_sips = async (cups_name, oferta_id) => {
  let datos = { }
  datos.cups_name = cups_name
  datos.oferta_id = oferta_id
  try {
    store.dispatch(setLoader("Buscando CUPS"));
    const res = await http.post(`/erp/cups`, { ...datos }, {
      params: { btn_import_sips: "btn_import_sips" },
    });
    return res.data.cups;
  } catch (err) {
    const mensaje = err.response.data.error;
    toast.error("Error al buscar el CUPS:" + mensaje, {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};

export const btn_search_sips = async (cups_name, sector_type) => {
  try {
    const res = await http.get(`/erp/cups?btn_search_sips=btn_search_sips`, {
      params: { cups_name, sector_type,},
    });
    return res.data.cups;
  } catch (err) {
    const mensaje = err.response.data.error;
    toast.error("Error al buscar el CUPS:" + mensaje, {
      position: toast.POSITION.TOP_CENTER,
    });
  }
};


export const get_cups = async (cups_name, user_id, oferta_id, sector_type) => {
  try {
    store.dispatch(setLoader("Buscando CUPS"));
    const res = await http.get(`/erp/cups?cups_name=${cups_name}`, {
      params: {
        cups_name,
        user_id,
        oferta_id,
        sector_type,
      },
    });
    return res.data.cups;
  } catch (err) {
    toast.error("No existe el CUPS, complete los campos.", {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};

export const set_cups_erp = async (cups_id, datos) => {
  const body = { cups_id, datos };
  try {
    store.dispatch(setLoader("Actualizando CUPS"));
    const res = await http.put(`/erp/cups`, body);
    return res.data.cups;
  } catch (err) {
    toast.error("Error al modificar el CUPS.", {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};

export const generar_precios = async (oferta_id, tipus, tarifa_id, potencias) => {
  const body = {
    oferta_id,
    tipus,
    tarifa_id,
    potencias,
  };
  try {
    store.dispatch(setLoader("Generando precios"));
    await http.post(`/erp/cups`, body, {
      params: { generar_precios: "generar_precios" },
    });
  } catch (err) {
    toast.error("Error al crear los precios.", {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};
