import { Row, Col, Container } from "react-bootstrap/";
import AVISO_LEGAL from "../../media/aviso_legal.pdf"
import POLITICA_PRIVACIDAD from "../../media/politica_privacidad.pdf"

const Footer = () => {
  return (
    <Container
      className="py-4 ab-primary mt-5 footer py-lg-3"
      style={{ minWidth: "100%" }}
    >
      <Row className="align-items-center justify-content-between mx-5">
        <Col md="auto" className="justify-content-center">
          <a href={AVISO_LEGAL} target="_blank" className="link">
            Aviso Legal
          </a>
        </Col>
        <Col md="auto" className="justify-content-center">
          <a href={POLITICA_PRIVACIDAD} target="_blank" className="link">
            Política de privacidad
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
