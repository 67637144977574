import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal, Row, Col, Button } from "react-bootstrap";

function GestionArchivos({ oferta, show, close }) {
  const navigate = useNavigate();
  const cerrar = () => {
    close(false);
  };

  return (
    <Modal show={show} backdrop="static" centered className="border border-0">
      <Modal.Header className="ab-primary justify-content-between align-items-center">
        <Col md="auto">
          <h2 className="text-white my-0">Modificar</h2>
        </Col>
        <Col md="auto">
          <h2 className="text-white my-0">Nº {oferta.name}</h2>
        </Col>
      </Modal.Header>
      <Modal.Body>
        <Row className="align-items-center justify-content-center mb-2">
          <Col md="4">
            <Button
              type="submit"
              className="button button-block"
              onClick={() =>
                navigate("/" + oferta.sector_type + "/tarifas/" + oferta.id)
              }
            >
              Tarifa
            </Button>
          </Col>
          <Col md="4">
            <Button
              type="submit"
              className="button button-block"
              onClick={() => navigate("/" + oferta.sector_type + "/partner/" + oferta.id)}
            >
              Cliente
            </Button>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center">
          <Col md="4">
            <Button
              type="submit"
              className="button button-block"
              onClick={() =>
                navigate("/" + oferta.sector_type + "/pagador/" + oferta.id, {
                  state: { editMode: true },
                })
              }
            >
              Pagador
            </Button>
          </Col>
          <Col md="4">
            <Button
              type="submit"
              className="button button-block"
              onClick={() =>
                navigate("/" + oferta.sector_type + "/cups/" + oferta.id, {
                  state: { editMode: true },
                })
              }
            >
              CUPS
            </Button>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="button rounded-5 button-secondary"
          variant="secondary"
          onClick={cerrar}
        >
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default connect()(GestionArchivos);
